/* eslint-disable prettier/prettier */
/* eslint-disable max-lines */
import { ReactNode, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { LENSES_PACKAGES, LOCALE_CODES, LOCALE_DICT } from '@constants';
import { Checkbox, EditabilityLensBody, Flex, PairCareBody } from '@components';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { Bundle, CartMutationLineItem, NormalizedCartLine } from '@ts/cart';
import { parseBaseFrameVariant } from '@utils/shopify';
import { useCartContext } from '@context';
import {
	BASE_FRAME_LENS_OPTIONS,
	BaseVariantPriceDictionary,
	getBaseFrameLensOptions,
	getRxType,
	RX_TYPE,
} from '@utils/constants/base-skus';
import { DynamicBaseFrameVariant, getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { CopyFields, CopyGroupFields } from '@ts/index';
import { fetchContentful } from '@services/contentful/client';
import { normalizeContentfulEntry } from '@utils/normalizers/normalize-contentful';
import { DISCOUNT_RATES, PRODUCT_TYPES, useIsVipMembership } from '@utils/index';
import { calculateDiscount } from '@utils/discount';
import { useFeatureInLocale } from '@utils/hooks';
import styles from './CheckboxUpsell.module.scss';

interface CheckboxUpsellProps {
	product?: NormalizedProduct;
	bundleKey: string;
	line?: NormalizedCartLine;
	tooltip?: boolean;
	base: Bundle['base'];
	disabled?: boolean;
	preSelectedTooltip?: boolean;
	variant?: NormalizedVariant;
	lensUpsell?: BASE_FRAME_LENS_OPTIONS;
	rxType?: RX_TYPE;
	variantPrices?: BaseVariantPriceDictionary;
	currentVariant?: DynamicBaseFrameVariant;
	children?: ReactNode;
	isRedesign?: boolean;
	className?: string;
	lensPack?: LENSES_PACKAGES;
	callback?: (item: CartMutationLineItem) => void;
	isBuildFlow?: boolean;
}
const CheckboxUpsell = ({
	product,
	bundleKey,
	line = null,
	tooltip = false,
	base,
	disabled = false,
	preSelectedTooltip = false,
	variant = product?.variants[0],
	lensUpsell,
	rxType,
	variantPrices,
	currentVariant,
	children,
	isRedesign = false,
	className,
	lensPack,
	callback,
	isBuildFlow = false,
}: CheckboxUpsellProps) => {
	const { locale } = useRouter();
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const { applyDiscountMembership } = useIsVipMembership(PRODUCT_TYPES.LENS);
	const { isCartMutating, handleCartAdd, handleCartRemove, handleBaseFrameUpsellAction } = useCartContext();
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const safeToClick = !disabled && !isCartMutating;
	const info = parseBaseFrameVariant(base.frame.variant.option);
	const isLightResponsiveSelected = info.lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const isBlueLightOption = lensUpsell === BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT;
	const [isChecked, setIsChecked] = useState<boolean>(
		!!line || info.lensType.includes(lensUpsell) || info.rxType === rxType || (isLightResponsiveSelected && isBlueLightOption)
	);

	const { data: lensUpsellCopy } = useQuery(['lens-upsell-copy'], async (): Promise<CopyGroupFields> => {
		const copyGroup = await fetchContentful<CopyGroupFields>({
			'content_type': 'copyGroup',
			'fields.slug': 'build-flow-lens-options-copy',
			'limit': 1,
			locale,
		});
		return normalizeContentfulEntry<CopyGroupFields>(copyGroup) as Promise<CopyGroupFields>;
	});


	useEffect(() => {
		setIsChecked(
			!!line ||
			info.lensType.includes(lensUpsell) ||
			info.rxType === rxType ||
			(isLightResponsiveSelected && isBlueLightOption)
		);
	}, [line, info, lensUpsell, rxType, isLightResponsiveSelected, isBlueLightOption]);
	const variantOption = lensUpsell || rxType;
	const variantOptionName = getBaseFrameLensOptions(locale, lensUpsell) || getRxType(locale, rxType);
	const variantPriceDiff =
		variantOption &&
		variantPrices &&
		getVariantPriceDiff({
			lookup: variantPrices,
			current: currentVariant,
			lens: lensUpsell,
			rxType,
			locale,
			currencyCode,
			lensPackage: lensPack,
			sunLensException: !!info.lensColor,
		});

	const dynamicPrice = !variantPriceDiff
		? null
		: isLightResponsiveSelected && isBlueLightOption
			? {
				amount: 0,
				currencyCode,
				locale,
			}
			: currentVariant.data.variantBySelectedOptions.title.includes(lensUpsell)
				? variantPriceDiff.without
				: variantPriceDiff.with;

	const discountedPrice = dynamicPrice ? calculateDiscount(
		applyDiscountMembership ? DISCOUNT_RATES.PERCENT_15 : 0,
		dynamicPrice.amount
	): null;

	const handleClick = useCallback(() => {
		if (!safeToClick) return;
		if (isCartUsability && callback) {
			const callbackPayload = isChecked
				? undefined
				: {
					id: product.id,
					variant,
					quantity: 1,
					customAttributes: [{ key: '_bundle_key', value: bundleKey }],
				};
			callbackPayload === undefined && setIsChecked(false);
			return callback(callbackPayload);
		}

		if (product?.handle === 'pair-care') {
			return isChecked
				? handleCartRemove([line.id])
				: handleCartAdd(
					[
						{
							id: product.id,
							variant: variant,
							quantity: 1,
							customAttributes: [
								{
									key: '_bundle_key',
									value: bundleKey,
								},
							],
						},
					],
					false
				);
		}

		return handleBaseFrameUpsellAction({
			lineToUpdate: base.frame,
			currentState: currentVariant.state,
			existingLenses: info.lensType,
			value: lensUpsell,
		});
	}, [
		currentVariant?.state,
		handleBaseFrameUpsellAction,
		lensUpsell,
		base?.frame,
		bundleKey,
		handleCartAdd,
		handleCartRemove,
		info,
		isChecked,
		line?.id,
		product,
		safeToClick,
		variant,
	]);
	const classes = cn(styles.checkbox, className, {
		[styles.hasChildren]: !!children,
		[styles.checkedContainer]: isChecked,
		[styles.disabledCheckedContainer]: disabled,
	});

	const dataTags = isBuildFlow
		? isChecked
			? { [`data-bf-remove`]: product?.name || lensUpsell }
			: { [`data-bf-add-on`]: product?.name || lensUpsell }
		: isChecked
			? { [`data-cart-checkbox-remove`]: product?.name || lensUpsell }
			: { [`data-cart-checkbox-add`]: product?.name || lensUpsell };

	return (
		<Flex className={styles.container} column>
			<Checkbox
				checked={isChecked}
				className={classes}
				disabled={!safeToClick}
				isFetching={line?.optimistic}
				option='pair care'
				hoverable={!isRedesign}
				dataTags={dataTags}
				handler={handleClick}
				hoverColor='gray'
			>
				{variantOption ? (
					<EditabilityLensBody
						name={variantOptionName}
						price={{ ...dynamicPrice, amount: discountedPrice }}
						copy={
							isChecked && (lensUpsellCopy?.blocks as Array<CopyFields>)?.find(block =>
									block.slug.includes(lensUpsell?.toLowerCase()?.replace(' ', '-'))
								)?.description
						}
						tooltip={tooltip}
						preSelectedTooltip={preSelectedTooltip}
					/>
				) : (
					<PairCareBody variant={variant} />
				)}
			</Checkbox>
			{!!children && (
				<Flex column gap={3} className={styles.childrenContainer}>
					{children}
				</Flex>
			)}
		</Flex>
	);
};
export default CheckboxUpsell;
