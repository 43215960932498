/* eslint-disable max-lines */
import { PRODUCT_TYPES } from '@constants';
import { CleanedCollectionProduct, CleanedProduct, NormalizedVariant, VariantMetafields } from '@ts/product';
import { Image, Product, ProductVariant } from '@ts/shopify-storefront-api';
import { NormalizedProduct } from 'ts/product';
import { BASE_REGEX } from '@utils/constants/base-skus';
import { isEmpty } from '@utils/objects';
import { METAFIELD_TYPES_REQUIRING_PARSE } from '@services/shopify/generate-metafield-queries';
import { capitalizeEachWord } from '../strings';

const money = ({ amount, currencyCode }) => {
	return {
		amount: +amount,
		currencyCode,
	};
};

const normalizeProductOptions = options => {
	return options.filter(o => o.name !== 'Title');
};

// Not used in the Shopify -> Pyrus normalization
export const normalizeProductType = type =>
	type?.includes('TOP')
		? PRODUCT_TYPES.TOP_FRAME
		: type?.includes('BASE')
			? PRODUCT_TYPES.BASE_FRAME
			: (type as (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES]);

export const normalizeProductTitle = (title, productType) => {
	if (productType?.includes(PRODUCT_TYPES.BASE_FRAME)) {
		return title.split(' - ')[0];
	}
	return title;
};

export const normalizeProductVariant = (
	{
		availableForSale,
		id,
		image,
		price,
		requiresShipping,
		sku,
		title,
		compareAtPrice,
		sellingPlanAllocations,
		quantityAvailable,
		currentlyNotInStock,
		...rest
	}: ProductVariant,
	product: Pick<Product, 'id' | 'handle' | 'title' | 'tags'> & {
		productType: NormalizedProduct['type'];
		normalizedMetafields: Partial<VariantMetafields>;
	}
): NormalizedVariant => {
	return {
		availableForSale,
		id,
		image,
		type: normalizeProductType(product.productType),
		handle: product.handle,
		metafields: {
			...product.normalizedMetafields,
			...normalizeMetafields(rest),
		},
		name: normalizeProductTitle(product.title, product.productType),
		option: title,
		price: money(price),
		product: {
			...product,
			name: normalizeProductTitle(product.title, product.productType),
			type: product.productType,
		},
		requiresShipping,
		compareAtPrice: compareAtPrice ? money(compareAtPrice) : null,
		sku,
		sellingPlans: normalizeSellingPlanAllocations(sellingPlanAllocations),
		quantityAvailable,
		currentlyNotInStock,
	};
};

const normalizeSellingPlanAllocations = sellingPlanAllocations => {
	return (
		sellingPlanAllocations?.map(({ sellingPlan, priceAdjustments }) => {
			return {
				...sellingPlan,
				price: priceAdjustments?.[0]?.price ?? { amount: 0, currencyCode: 'USD' },
			};
		}) || []
	);
};

// Utilizes altText from GQL response
const normalizeVariantImages = images => {
	const normalized = images.reduce((accumulator, current) => {
		let name = current.altText;

		if (name) {
			name = capitalizeEachWord(name); //Always get correct string from altText
			if (!accumulator[name]) {
				accumulator[name] = [];
			}

			accumulator[name].length < 3 && accumulator[name].push(current);
		}
		return accumulator;
	}, {});

	return Object.entries(normalized).length > 0 ? normalized : null;
};

type MetafieldTuple = [string, { id: string; type: string; value: string; key: string }];

const getTrueMetafieldValue = metafield => {
	if (METAFIELD_TYPES_REQUIRING_PARSE.includes(metafield?.type)) {
		return JSON.parse(metafield.value);
	}
	if (metafield?.reference?.image) {
		return metafield.reference.image;
	}
	if (metafield?.reference?.sources) {
		return metafield.reference;
	}
	return metafield.value;
};

export const normalizeMetafields = props => {
	const metafields = Object.entries(props).filter(prop => prop[0].includes('meta_'));
	return metafields.length
		? metafields.reduce((accumulator, [name, metafield]: MetafieldTuple) => {
				if (metafield) {
					name = name.split('_')[1];
					accumulator[name] = getTrueMetafieldValue(metafield);
				}
				return accumulator;
			}, {})
		: null;
};

export const normalizeProductId = id => {
	const processedID = /[^/]*$/.exec(id)[0];

	return processedID;
};

export function normalizeProduct({
	availableForSale,
	description,
	descriptionHtml,
	collections,
	handle,
	id,
	images,
	options,
	productType,
	priceRange,
	tags,
	title,
	totalInventory,
	variants,
	sellingPlanGroups,
	...rest
}: CleanedProduct): NormalizedProduct {
	const normalizedMetafields = normalizeMetafields(rest);

	return {
		availableForSale,
		handle,
		id: normalizeProductId(id),
		name: normalizeProductTitle(title, productType),
		price: money(priceRange?.minVariantPrice),
		path: `/${handle}`,
		slug: handle?.replace(/^\/+|\/+$/g, ''),
		tags: tags ? tags : [],
		totalInventory,
		type: productType,
		options: options ? normalizeProductOptions(options) : [],
		...(collections && { collections }),
		...(description && { description }),
		...(descriptionHtml && { descriptionHtml }),
		...(images && { images: images as Image[] }),
		...(images && { variantImages: normalizeVariantImages(images) }),
		...(normalizedMetafields && { metafields: normalizedMetafields }),
		...(variants && {
			variants: variants.map(v =>
				normalizeProductVariant(v, { id, handle, productType, title, tags, normalizedMetafields })
			),
		}),
		sellingPlans: sellingPlanGroups?.[0]?.sellingPlans || [],
	};
}

export const normalizeCollectionProduct = ({
	id,
	handle,
	title,
	variantBySelectedOptions,
	productType,
	tags,
	images: rawImages,
	...rest
}: CleanedCollectionProduct): NormalizedVariant => {
	const nmType = normalizeProductType(productType);
	const images = rawImages?.filter(image => image.url.includes(variantBySelectedOptions.title.toUpperCase()));

	return {
		handle,
		availableForSale: variantBySelectedOptions.availableForSale,
		requiresShipping: variantBySelectedOptions.requiresShipping,
		compareAtPrice: variantBySelectedOptions.compareAtPrice ? money(variantBySelectedOptions.compareAtPrice) : null,
		id: variantBySelectedOptions.id,
		name: title ?? '',
		type: nmType,
		option: variantBySelectedOptions.title,
		sku: variantBySelectedOptions.sku,
		image: variantBySelectedOptions.image,
		images,
		price: money(variantBySelectedOptions.price),
		product: {
			handle,
			id,
			name: title,
			type: nmType,
			tags,
		},
		sellingPlans: normalizeSellingPlanAllocations(variantBySelectedOptions.sellingPlanAllocations),
		metafields: {
			...normalizeMetafields(variantBySelectedOptions),
			...normalizeMetafields(rest),
		},
	};
};

export function transformCollectionsToProducts(collections): NormalizedProduct[] {
	return collections.map(rawCollection => {
		let variantImages = {};
		rawCollection.products.forEach(product => {
			variantImages = {
				...variantImages,
				...product.variantImages,
			};
		});

		const { metafields } = rawCollection.products.find(p => !isEmpty(p.metafields));
		const minValue = Math.min(...rawCollection.products.map(p => p.price.amount));
		const productsWithMinPrice = rawCollection.products.filter(p => p.price.amount === minValue);
		const [productWithMinPrice] = productsWithMinPrice;

		return {
			id: rawCollection.products[0].id,
			handle: rawCollection.handle,
			name: rawCollection.name,
			availableForSale: rawCollection.products.some(product => product.availableForSale),
			price: productWithMinPrice.price,
			images: rawCollection.products[0].images,
			tags: Array.from(new Set(rawCollection.products.flatMap(product => product.tags))),
			type: rawCollection.products[0].type,
			metafields,
			description: rawCollection.products[0].description || null,
			variants: rawCollection.products.map(product => {
				const isMetalBF = product.handle.includes('metal');
				let compareAtPrice;

				if (isMetalBF) {
					const basicMetal = product.variants.find(v => v.option.includes('Non-RX / Basic'));
					compareAtPrice = basicMetal.compareAtPrice || null;
				} else {
					compareAtPrice = productsWithMinPrice.includes(product)
						? productWithMinPrice?.variants?.reduce((prev, curr) =>
								prev.price.amount < curr.price.amount ? prev : curr
							)?.compareAtPrice || null
						: null;
				}

				return {
					availableForSale: product.availableForSale,
					id: product.variants?.[0]?.id ?? product.id,
					handle: product.handle,
					image: product.images?.[0],
					name: product.name,
					option: product.options?.[0]?.values?.[0],
					type: product.type,
					price: product.price,
					// Added as elevar needs to have this
					product: {
						id: product.id,
					},
					compareAtPrice,
					sku: product.variants[0].sku.match(BASE_REGEX)[1],
					metafields: product.variants[0].metafields,
					description: product.description || null,
					tags: product.tags || [],
				};
			}),
			variantImages,
		};
	});
}
