import { useRouter } from 'next/router';
import { FRAME_COLOR_IMAGE_LINKS, FRAME_COLORS, getFrameColors } from '@constants';
import { ConditionalLink, Img, Lozenge } from '@components';

import variables from '@styles/export.module.scss';
import { frameSwatchesProps } from '@ts/components';
import styles from './Swatch.module.scss';

type SwatchProps = {
	callback: ({ option, type }: { option: string; type: 'frame' | 'lens' | 'option' }) => void;
	activateOnHover: boolean;
	index: number;
	name: string;
	option: string;
	selected: string;
	type: 'frame' | 'lens' | 'option';
	frameSwatches?: frameSwatchesProps;
	tag?: { label: string; type: 'success' | 'error' };
};

const Swatch = ({
	callback,
	activateOnHover = false,
	index = null,
	name = '',
	option = '',
	selected = '',
	type = 'frame',
	frameSwatches = null,
	tag = null,
}: SwatchProps) => {
	const { query, asPath, locale } = useRouter();
	const elementProps = {
		'data-position': index,
		'data-swatch-type': type,
		'data-product-title': name,
		'title':
			type === 'option'
				? frameSwatches?.[option].name
				: Object.values(FRAME_COLORS).includes(option as FRAME_COLORS)
					? getFrameColors(locale, option as FRAME_COLORS)
					: option,
		'onClick': e => {
			if (frameSwatches) return;
			e.stopPropagation();
			callback({ option, type });
		},
		'width': 32,
		'height': 32,
		[`data-${type}-color`]: option,
		...(activateOnHover && { onMouseEnter: () => callback({ option, type }) }),
	};
	const dataTestTags = type === 'lens' ? { 'data-lens-color': option } : { 'data-color': option };
	const dataCollectionSwatch = type === 'option' ? { 'data-collection-swatch': frameSwatches?.[option].name } : {};

	const element =
		type === 'frame' || type === 'option' ? (
			<ConditionalLink
				href={`/top-frames/${query.collection}/${frameSwatches?.[option].handle}${asPath.split('?')?.[1] ? `?${asPath.split('?')[1]}` : ''}`}
				condition={!!frameSwatches}
				title={elementProps.title}
				className={styles['shadow']}
			>
				<Img
					className={styles['swatch']}
					src={FRAME_COLOR_IMAGE_LINKS[option] ?? option}
					noSrcset
					alt={`${elementProps.title} swatch`}
					{...elementProps}
					{...dataTestTags}
					{...dataCollectionSwatch}
				/>
			</ConditionalLink>
		) : (
			<div className={styles['swatch']} {...elementProps} {...dataTestTags} />
		);

	return (
		<li className={styles['wrapper']} data-selected={selected === option}>
			{tag && (
				<Lozenge
					extraClasses={styles['lozenge']}
					shape='square'
					text={tag.label}
					backgroundColor={tag.type === 'success' ? variables.greenLight : variables.gray2}
					color={tag.type === 'success' ? variables.green2 : variables.gray4}
				/>
			)}
			{element}
		</li>
	);
};

export default Swatch;
