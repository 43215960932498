var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5da79bf29149248a5bda744aa5cad93564b9e508"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { NEXT_APP_ENV } from '@constants';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN,
	sampleRate: 0.25,
	// Relates to Sentry performance that we don't use
	tracesSampleRate: 0,
	environment: process.env.NEXT_PUBLIC_APP_ENV,
	// disables for anything but prod - use this while we get errors under control
	enabled: process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,

	replaysOnErrorSampleRate: 0,

	// This sets the sample rate to be 0%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0,
});
