import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Flex, SwatchController, SwatchOption } from '@components';
import {
	FRAME_COLOR_SORTING,
	LENS_COLORS,
	LIMITED_COLORS,
	METAL_FRAME_COLORS,
	MIXED_FRAME_COLOR_SORTING,
	NEW_METAL_FRAME_COLORS,
	PRODUCT_TYPES,
} from '@constants';
import { BaseFrameState } from '@utils/hooks/useBaseFrame';
import { normalizeProductType } from '@utils/normalizers';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		new: translator('new'),
	};
};

const VariantController = ({ product, state, dispatch, showLensController }) => {
	const { query } = useRouter();
	const isMetalBaseFrame = query?.base === 'Metal' || product.handle.includes('mixed-material');
	const type = normalizeProductType(product.type);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isColorRefresh = useFeatureIsOn('is-color-refresh-2');

	const frameColors: Array<SwatchOption> = [];
	const metalFrameColors: Array<SwatchOption> = [];

	product.variants.forEach(variant => {
		const color = variant.option;
		const isRefreshColors = NEW_METAL_FRAME_COLORS.includes(color);
		if (!isColorRefresh && isRefreshColors) return;
		const shouldAddNewTag = isColorRefresh ? isRefreshColors : LIMITED_COLORS.includes(color);
		const tag: SwatchOption['tag'] = shouldAddNewTag ? { label: translations.new, type: 'success' } : undefined;
		const objColor = { name: color, tag };

		if (METAL_FRAME_COLORS.includes(color)) {
			metalFrameColors.push(objColor);
		} else {
			frameColors.push(objColor);
		}
	});

	if (!(product.variants.length > 0)) return null;

	switch (type) {
		case PRODUCT_TYPES.BASE_FRAME || PRODUCT_TYPES.BASE_FRAME_SR:
			return (
				<>
					<Flex style={{ flex: 1 }} gap={3} center>
						<SwatchController
							options={
								isMetalBaseFrame
									? metalFrameColors.sort(
											(a, b) => MIXED_FRAME_COLOR_SORTING[a.name] - MIXED_FRAME_COLOR_SORTING[b.name]
										)
									: frameColors.sort((a, b) => FRAME_COLOR_SORTING[a.name] - FRAME_COLOR_SORTING[b.name])
							}
							callback={dispatch}
							name={product.name}
							selected={(state as BaseFrameState).frame}
							type={'frame'}
							alignment='left'
							activateOnHover
							parent={'product-card'}
						/>
					</Flex>
					{showLensController && (
						<SwatchController
							options={Object.values(Object.values(LENS_COLORS))}
							callback={dispatch}
							name={product.name}
							selected={(state as BaseFrameState).lens ?? LENS_COLORS.BLACK}
							title={'with'}
							type={'lens'}
							alignment='left'
							activateOnHover
							parent={'product-card'}
						/>
					)}
				</>
			);

		default:
			return null;
	}
};

export default VariantController;
