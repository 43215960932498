/* eslint-disable max-lines */
import { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { CUSTOMER_JOURNEYS, LOCALE_CODES, PRODUCT_TYPES, READER_STRENGTHS, SUBMISSION_METHODS } from '@constants';
import {
	Button,
	Caption,
	CartPD,
	CheckboxUpsell,
	Flex,
	Heading,
	Img,
	LineItemCard,
	Loading,
	Modal,
	Paragraph,
	PrescriptionOptions,
	ProtectYourLenses,
	Select,
	StyledWordEmphasizer,
	Title,
	TypographyButton,
} from '@components';
import { useCart, useProductQuery } from '@services/shopify';
import { Bundle, DoctorInfoAttributes } from '@ts/cart';
import { SubmissionMethod } from '@ts/poms';
import { NormalizedProduct } from '@ts/product';
import { CurrencyCode } from '@ts/shopify-storefront-api';
import {
	BADGE_TYPES,
	DISCOUNT_RATES,
	findLensPackageInLensOptions,
	formatCurrency,
	FRAME_COLORS,
	generateSunglassImages,
	getBaseName,
	getCheckoutTokenFromGID,
	getDeleteBaseFrame,
	getLensesSupportCopy,
	LENS_COLORS,
	LOCALE_DICT,
	useIsVipMembership,
	useParseBaseFrameVariant,
	useTranslation,
} from '@utils/index';
import variables from '@styles/export.module.scss';
import { CART_LINE_ATTRIBUTE_KEYS, emptyUpsellLine, emptyUpsellProduct } from '@utils/constants/cart';
import { useCartContext } from '@context';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	getVariantPriceDiff,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useFeatureInLocale } from '@utils/hooks';
import { calculateDiscount } from '@utils/discount';
import {
	addCustomAttributes,
	getCollectionPathFromProperties,
	getEditPath,
	getReaderStrengthPayload,
	getSubmissionMethodPayload,
	removeCustomAttributes,
} from '@utils/cart';
import styles from './BaseFrameBundle.module.scss';

const { REMIND } = SUBMISSION_METHODS;

const getTranslatedTexts = translator => {
	return {
		aboutToRemove: (prodcutRemoved: string) => translator('about-to-remove', { prodcutRemoved }),
		removeLensAndSubs: translator('remove-lens-and-subs'),
		removeLensSelection: translator('remove-lens-selection'),
		varifocal: translator('varifocal'),
		myBaseFrame: translator('my-base-frame'),
		edit: translator('edit'),
		remove: translator('remove'),
		cancel: translator('cancel'),
		readerStrength: translator('reader-strength'),
		myLenses: translator('my-lenses'),
		rxTypeIsProgressive: (rxType: string, lensPack: string) => translator('rx-type-is-progressive', { rxType, lensPack }),
	};
};

type BaseFrameBundleProps = {
	cartId: string;
	bundle: Bundle;
	isLoading: boolean;
	pairCare: NormalizedProduct;
	forceMobileStyle?: boolean;
	tags?: (typeof BADGE_TYPES.TOP_RATED)[];
	isMinicart?: boolean;
};

const BaseFrameBundle = ({ bundle, pairCare, forceMobileStyle = false, isLoading, tags }: BaseFrameBundleProps) => {
	const router = useRouter();
	const { locale } = router;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const isBothUpsellsVariant = useFeatureIsOn('both-upsells-test');
	const isPremiumVariant = useFeatureIsOn('show-premium-upsell');
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const isCartPD = useFeatureInLocale('is-pd-in-cart', LOCALE_CODES.US);
	const { applyDiscountMembership } = useIsVipMembership(PRODUCT_TYPES.BASE_FRAME);

	const isCartUpsellTest = isBothUpsellsVariant || isPremiumVariant;

	const { data: cart } = useCart();
	const { handleCartRemove, handleCartUpdate } = useCartContext();
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	const { base, optimistic: optimisticState, key: bundleKey, subscriptionProduct } = bundle;
	const withSubscription = !!subscriptionProduct;
	const { frame, prescription, insurance } = base;
	const { variant, properties, id: frameId } = frame;
	const {
		handle = '',
		option: fullVariantOption,
		name: productFrameTitle,
		type: varianttype,
		image: variantImg,
		name: frameTitle,
	} = variant;
	const { url: variantImgUrl } = variantImg;
	const { _customerJourney, _readerStrength, _submissionMethod = REMIND, _pdMeasurement } = properties;
	const { id: insuranceId } = insurance ?? { id: '' };
	const { type: prescriptionType } = prescription;
	const isSunglasses = _customerJourney === CUSTOMER_JOURNEYS.SUNGLASSES;
	const baseFrameHeadCount = cart?.lines?.filter(line => line?.variant?.type?.includes(PRODUCT_TYPES.BASE_FRAME)).length;

	const { frameColor, lensColor, lensType, rxType } = useParseBaseFrameVariant(fullVariantOption);
	const lensTypesToDisplay = lensType.filter(lens => {
		if (!isSunglasses) {
			return lens !== BASE_FRAME_LENS_OPTIONS.CR39 && lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
		}
		return lens !== BASE_FRAME_LENS_OPTIONS.CR39 && lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
	});

	const isBlueLensInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT);
	const isLightResponsiveInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);
	const isNonRx = rxType === 'Non-RX';
	const isReaders = rxType === 'Readers';
	const isBlueLightUpsellVisible = !isBlueLensInCart && !isSunglasses && (!isPremiumVariant || !isCartUpsellTest);
	const isRxOptionsVisible = !!bundleKey && !isLoading && !isNonRx && !isReaders;
	const showUpsellIncentiveCopy = !isSunglasses && isBlueLightUpsellVisible && !isLightResponsiveInCart;
	const sunGlasses = [];
	const prescriptionLensPrice = { amount: 0, currencyCode: CurrencyCode.Usd };
	const submissionMethodState = useState(_submissionMethod as SubmissionMethod);
	const { data: product } = useProductQuery(handle, { country, language });
	const variantImages = product?.variantImages;
	const lensPack = !isSunglasses ? findLensPackageInLensOptions(lensType) : null;
	const DELETE_BASE_FRAME = getDeleteBaseFrame(locale, productFrameTitle);
	const LENSES_SUPPORT_COPY = getLensesSupportCopy(locale);

	const BaseFrameVariant = useBaseFrameVariant({
		'handle': base.frame.variant.handle,
		'Color': frameColor,
		'Rx Type': rxType,
		'Lens': createBaseFrameVariant(lensType),
		country,
		language,
	});

	const { data: variantPrices, isLoading: variantPricesAreLoading } = useBaseFrameVariantPrices(
		base.frame.variant.handle,
		country,
		language
	);

	const sunglassImages = useMemo(
		() =>
			generateSunglassImages({
				name: getBaseName(productFrameTitle),
				baseColor: frameColor as FRAME_COLORS,
				lensColor: (isSunglasses ? lensColor : '') as LENS_COLORS,
				length: 3,
			}),
		[frameColor, sunGlasses[0]?.variant?.option, lensColor]
	);

	const removeAllFromBundle = () => {
		handleCartRemove(
			[...(insuranceId && [insuranceId]), ...(withSubscription ? [subscriptionProduct?.id] : []), frameId],
			variant,
			getCollectionPathFromProperties(properties)
		);
	};

	const updateReaderStrength = (strength: (typeof READER_STRENGTHS)[number]) => {
		const payload = getReaderStrengthPayload(strength, frame, properties);
		handleCartUpdate([payload]);
	};

	const updatePDMeasurement = async (pd: number | null, method: string) => {
		const attributesToUpdate = [CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT, CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT_METHOD];
		let payload;

		if (!pd) {
			payload = removeCustomAttributes(attributesToUpdate, frame, properties);
		} else {
			payload = addCustomAttributes(
				[
					{
						key: CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT,
						value: pd.toString(),
					},
					{
						key: CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT_METHOD,
						value: method,
					},
				],
				frame,
				properties
			);
		}

		handleCartUpdate([payload]);
	};

	const updateBundleSubmissionMethod = (extraProperties: DoctorInfoAttributes = {}) => {
		const isEditableBaseFrame = !properties || !frameId;
		const isSubmissionChanging = submissionMethodState[0] === _submissionMethod;

		if (isEditableBaseFrame || isSubmissionChanging) return;

		const payload = getSubmissionMethodPayload(properties, submissionMethodState[0], frame, extraProperties);
		frame && handleCartUpdate([payload]);
	};

	const memoizedRxOptions = useMemo(() => {
		return (
			<PrescriptionOptions
				updatePDMeasurement={updatePDMeasurement}
				updateBundleSubmissionMethod={updateBundleSubmissionMethod}
				submissionMethodState={submissionMethodState}
				bundleKey={bundleKey}
				checkoutToken={getCheckoutTokenFromGID(cart?.id)}
				hasMeasuredPd={!!properties[CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT]}
			/>
		);
	}, [base, submissionMethodState, isLoading]);

	const baseFrameImages = (image, index) => (
		<SwiperSlide key={index} className={'swiper-no-swiping'}>
			<Flex center>
				<Img
					needsPlaceholder
					alt={image.altText ? `${productFrameTitle} - ${image.altText}` : productFrameTitle}
					src={image.url}
					height={155}
					width={311}
					style={{ background: variables.gray0 }}
				/>
			</Flex>
		</SwiperSlide>
	);
	const SwiperBundle = useMemo(
		() => (
			<Flex center>
				<Swiper
					loop
					direction='horizontal'
					speed={600}
					modules={[Autoplay, EffectFade]}
					effect='fade'
					autoplay={{ delay: 2500 }}
					noSwiping
					className={styles['swiper-container']}
				>
					{isSunglasses
						? sunglassImages && sunglassImages.map((sunglassImage, index) => baseFrameImages(sunglassImage, index))
						: variantImages && variantImages[frameColor].map((color, index) => baseFrameImages(color, index))}
				</Swiper>
			</Flex>
		),
		[variantImages, sunglassImages, isSunglasses, isCartUsability, frameColor]
	);

	const handleEdit = async () => {
		const { basePath, queryParams } = getEditPath(
			bundleKey,
			frameColor,
			properties,
			_customerJourney,
			handle,
			withSubscription
		);
		const currentPath = router.asPath?.split('?')[0];

		if (currentPath === basePath) document.getElementById('minicart')?.click();

		router.push(basePath + queryParams);
	};

	if (!base) return null;
	if (isLoading || BaseFrameVariant.isLoading) return <Loading />;

	const lenses = (
		<>
			{/* LENSES */}
			{lensTypesToDisplay.map(lensLine => {
				return (
					<CheckboxUpsell
						disabled={isSunglasses}
						key={`lensLine-${lensLine}`}
						base={base}
						bundleKey={bundleKey}
						tooltip
						lensUpsell={lensLine}
						variantPrices={variantPrices}
						currentVariant={BaseFrameVariant}
						isRedesign={isCartUpsellTest && !isSunglasses}
						lensPack={lensPack}
					/>
				);
			})}
			{/* LENS UPSELLS */}
			{isBlueLightUpsellVisible && (
				<CheckboxUpsell
					base={base}
					lensUpsell={BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT}
					bundleKey={bundleKey}
					variantPrices={variantPrices}
					currentVariant={BaseFrameVariant}
					isRedesign={isCartUpsellTest}
					disabled={isLightResponsiveInCart}
					lensPack={lensPack}
				/>
			)}
		</>
	);

	const lensPackPriceDiff =
		!isSunglasses && !variantPricesAreLoading
			? getVariantPriceDiff({
					lookup: variantPrices,
					current: BaseFrameVariant,
					lensPackage: lensPack,
					rxType,
					locale,
					currencyCode: LOCALE_DICT[locale].currencyCode,
				})
			: null;

	const rxPriceDiff =
		!isSunglasses && !variantPricesAreLoading
			? getVariantPriceDiff({
					lookup: variantPrices,
					current: BaseFrameVariant,
					rxType,
					locale,
					currencyCode: LOCALE_DICT[locale].currencyCode,
				})
			: null;
	const totalPackPrice =
		!isSunglasses && !!rxPriceDiff && !!lensPackPriceDiff
			? {
					with: {
						...lensPackPriceDiff.with,
						amount: lensPackPriceDiff.with.amount + rxPriceDiff.with.amount,
					},
				}
			: null;

	const discountedPrice =
		totalPackPrice?.with &&
		calculateDiscount(applyDiscountMembership ? DISCOUNT_RATES.PERCENT_15 : 0, totalPackPrice?.with.amount);

	const priceLenses = totalPackPrice?.with.amount ? { ...totalPackPrice.with, amount: discountedPrice } : totalPackPrice?.with;

	return (
		<Flex column gap={4} maxWidth className={forceMobileStyle ? styles.containerInDrawer : styles.container}>
			<Flex justify={'end'} align='center'>
				<Flex justify='end' gap={4}>
					<Button size='small' linkStyle onClick={handleEdit} dataTags={{ 'data-cart-edit': handle }}>
						{translations.edit}
					</Button>
					<TypographyButton small style={{ color: variables.gray4 }}>
						|
					</TypographyButton>
					<Modal>
						<Modal.Trigger asChild>
							<Button size='small' linkStyle data-open-remove-modal={productFrameTitle}>
								{translations.remove}
							</Button>
						</Modal.Trigger>
						<Modal.Content center removePadding className={styles.deleteBaseFrameModal}>
							<Flex className={styles.imageContainer} align='center' justify='center'>
								<img src={isSunglasses ? sunglassImages[0].url : variantImgUrl} alt='frame variant' />
							</Flex>
							<div className={styles.bodyContainer}>
								<Heading tag='h5'>{DELETE_BASE_FRAME.TITLE}</Heading>
								<Paragraph>
									<StyledWordEmphasizer
										text={translations.aboutToRemove(productFrameTitle)}
										emphasizedWords={productFrameTitle}
										styledTexts={<b>{productFrameTitle}</b>}
										conditionalText={
											withSubscription ? translations.removeLensAndSubs : translations.removeLensSelection
										}
									/>
								</Paragraph>
								<Flex gap={3}>
									<Modal.Close asChild>
										<Button color='white'>{translations.cancel}</Button>
									</Modal.Close>
									<Modal.Close asChild>
										<Button
											onClick={() => removeAllFromBundle()}
											style={{ flex: 2 }}
											data-remove={productFrameTitle}
											data-type-remove={varianttype}
										>
											{translations.remove}
										</Button>
									</Modal.Close>
								</Flex>
							</div>
						</Modal.Content>
					</Modal>
				</Flex>
			</Flex>
			{!!variantImages && !isLoading && SwiperBundle}
			<Paragraph className={styles.bundleTitle}>{translations.myBaseFrame}</Paragraph>
			{base && !isLoading && (
				<>
					<Flex gap={4} column={isCartUsability && forceMobileStyle}>
						<LineItemCard
							key={frameTitle}
							data={frame}
							dataSource={'shopify'}
							deletionCallback={removeAllFromBundle}
							optimistic={optimisticState}
							baseFrame={base}
							showTags
							textOnly
							customTags={tags}
							positionTag='right'
							style={{ height: 'auto' }}
						/>
					</Flex>
					<ProtectYourLenses
						bundle={bundle}
						pairCare={pairCare}
						enableDefaultStyles={isCartUpsellTest || !isSunglasses}
						showTitle={!isSunglasses}
					/>
				</>
			)}
			{_readerStrength && (
				<Flex align='center' justify='between' className={styles.readerStrengthDropdown}>
					<Title>{translations.readerStrength}</Title>
					<Select
						values={READER_STRENGTHS.map(rs => `+${rs}`)}
						handler={updateReaderStrength}
						placeholder={`+${_readerStrength}`}
					/>
				</Flex>
			)}
			<Flex column gap={4} fullWidth className={styles.section}>
				{!isCartUpsellTest && (
					<Flex justify='between' align='end'>
						<Flex column>
							<Paragraph className={styles.bundleTitle} style={{ paddingLeft: 0, marginTop: 0 }}>
								{translations.myLenses}
							</Paragraph>
							{!isSunglasses && (
								<Paragraph className={styles.lensPackage}>
									{translations.rxTypeIsProgressive(
										locale === LOCALE_CODES.GB && rxType === RX_TYPE.PROGRESSIVE
											? translations.varifocal
											: rxType,
										lensPack
									)}
								</Paragraph>
							)}
						</Flex>
						{!isSunglasses && totalPackPrice ? (
							<TypographyButton small style={{ color: variables.gray4 }}>
								{formatCurrency(priceLenses, showCurr)}
							</TypographyButton>
						) : (
							<div />
						)}
					</Flex>
				)}
				{isCartUpsellTest && (
					<Flex justify='between' align='end'>
						<Flex column>
							<Caption>{translations.myLenses}</Caption>
							{!isSunglasses && (
								<Paragraph className={styles.lensPackage}>
									{translations.rxTypeIsProgressive(
										locale === LOCALE_CODES.GB && rxType === RX_TYPE.PROGRESSIVE
											? translations.varifocal
											: rxType,
										lensPack
									)}
								</Paragraph>
							)}
						</Flex>
						{!isSunglasses && totalPackPrice ? (
							<TypographyButton small style={{ color: variables.gray4 }}>
								{formatCurrency(priceLenses, showCurr)}
							</TypographyButton>
						) : (
							<div />
						)}
					</Flex>
				)}
				{/* RX TYPE */}
				{isSunglasses && (
					<CheckboxUpsell
						className={isCartUpsellTest ? styles.transparent : null}
						disabled
						base={base}
						product={{
							...emptyUpsellProduct,
							name: rxType ?? prescriptionType,
							handle: rxType,
							price: prescriptionLensPrice,
						}}
						bundleKey={bundleKey}
						line={{
							...emptyUpsellLine,
							title: rxType,
						}}
						preSelectedTooltip
						rxType={rxType}
						variantPrices={variantPrices}
						currentVariant={BaseFrameVariant}
					/>
				)}
				{showUpsellIncentiveCopy && isCartUpsellTest && (
					<Flex justify='start' align='start' fullWidth gap={3} className={styles.lensUpsellIncentive}>
						<Caption small>{LENSES_SUPPORT_COPY.blueLight}</Caption>
					</Flex>
				)}
				{!variantPricesAreLoading && lenses}
			</Flex>
			{isRxOptionsVisible && memoizedRxOptions}
			{isCartPD && baseFrameHeadCount === 1 && <CartPD callback={updatePDMeasurement} pdMeasurement={_pdMeasurement} />}
		</Flex>
	);
};

export default BaseFrameBundle;
