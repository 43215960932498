const es = {
  // =============== A ===============
  "about-our-sun-tops": "",
  "about-product": ({ productName }: { productName: string }) => `${productName}`,
  "about-to-remove": ({ prodcutRemoved }: { prodcutRemoved: string }) => `${prodcutRemoved}`,
  "accessories": "",
  "acetate": "",
  "account": "",
  "account-being-activated": "",
  "account-being-created": "",
  "account-card-list-items": ({ itemsNumber }: { itemsNumber: string }) => `${itemsNumber}`,
  "account-card-list-item": ({ itemNumber }: { itemNumber: string }) => `${itemNumber}`,
  "account-menu": "",
  "activate-account": "",
  "add": "",
  "add-od": "",
  "add-os": "",
  "add-to-cart": "",
  "add-to-cart-tops-redirected": "",
  "add-to-cart-plus": "",
  "add-uppercase": "",
  "added": "",
  "added-to-cart": "",
  "added-to-wishlist": "",
  "add-plus-continue": "",
  "add-a-top-frame": "",
  "adding": "",
  "add-to-cart-go-to-tops": "",
  "age-range-5-9": "",
  "age-range-8-12": "",
  "age-range-9-adult": "",
  "all-about-subscriptions": "",
  "all-about-our-subscriptions": "",
  "all-best-things-pairs": "",
  "all-duties-and-taxes": "",
  "all-time": "",
  "all-tops": "",
  "already-have-account-question": ({ signInText }: { signInText: string }) => `${signInText}`,
  "alt-all-tops": ({ label }: { label: string }) => `${label}`,
  "alt-collection-logo": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle}`,
  "alt-collection": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle}`,
  "alt-side-image": "",
  "alt-text-blue-swatch": "",
  "attention": "",
  "attention-lowercase": "",
  "are-you-sure": "",
  "are-you-want-to-exit": "",
  "aria-label-previous-slide": "",
  "aria-label-previous-slide-capitalized": "",
  "aria-label-next-slide": "",
  "aria-label-next-slide-capitalized": "",
  "ask-a-stylist": "",
  "axis": "",
  "axis-od": "",
  "axis-os": "",
  // =============== B ===============
  "back": "",
  "back-in-stock": "",
  "back-to-my-orders": "",
  "background-pattern": "",
  "badge-best": "",
  "badge-top-rated": "",
  "base-frame": "",
  "base-frame-below": "",
  "base-frame-color": "",
  "base-frame-size": "",
  "base-frame-shape": "",
  "base-frame-sold-separately": "",
  "base-frames": "",
  "base-frames-benefits": "",
  "base-in-bi": "",
  "base-out-bo": "",
  "base-up-bu": "",
  "base-down-bd": "",
  "basic": "",
  "bifocal-lenses-modern-alternative": "",
  "bl+lr": "",
  "bl": "",
  "blue-light": "",
  "blue-light-filtering": "",
  "blue-light-offerings": "",
  "browser-no-video-support": "",
  "bridge-width-size": ({ bridgeWidth }: { bridgeWidth: string }) => `${bridgeWidth}`,
  "bridge-width-title": "",
  "bridge-width-description": "",
  "buy-more": "",
  "build-and-purchase": "",
  "build-bundle-and-save": "",
  "build-from-previous-order": "",
  "build-purchase-edit-frame-save": "",
  "build-your-product": ({ productName }: { productName: string }) => `${productName}`,
  "build-you-bundle-title": "",
  "build-you-bundle-subtitle": "",
  "build-you-bundle-size": "",
  "build-you-bundle-base": "",
  "build-you-bundle-top": "",
  "build-you-bundle-top-title": "",
  "bundle-and-save": "",
  // =============== C ===============
  "call-doctor-question": "",
  "call-doctor-confirmation": "",
  "cart-icon": "",
  "cancel": "",
  "code-name-discount-pair": "",
  "color": "",
  "color-black": "",
  "color-blue-clear": "",
  "color-blue-reflective": "",
  "color-blue-tortoise": "",
  "color-brown": "",
  "color-green": "",
  "color-green-reflective": "",
  "color-tortoise": "",
  "color-pink-clear": "",
  "color-pink-reflective": "",
  "color-honey-clear": "",
  "color-gray-clear": "",
  "color-green-clear": "",
  "color-lavender-clear": "",
  "color-silver-reflective": "",
  "collection": "",
  "collection-uppercase": "",
  "collection-leaving": "",
  "collection-logo": "",
  "collection-title": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle}`,
  "complex-rx-note": "",
  "complete-your-order-message": "",
  "complete-uncropped-picture": "",
  "compare": "",
  "confirm-password": "",
  "continue": "",
  "continue-entering-rx": "",
  "continue-to-cart": "",
  "continue-shopping": "",
  "continue-shopping-title-case": "",
  "classic": "",
  "cleaning-kit": "",
  "clear": "",
  "close": "",
  "close-this-dialogue": "",
  "close-microcart-popup": "",
  "close-uppercase": "",
  "click-to-preview": "",
  "click-to-upload-file": "",
  "complete-your-pair": "",
  "contact-customer-service": "",
  "contact-customer-service-suggestion": "",
  "contact-us": "",
  "contact-us-button-title": "",
  "coming-soon": "",
  "check-order-status": "",
  "check-readers-strength": "",
  "checkout": "",
  "checkout-collections": "",
  "check-pd-in-prescription": "",
  "choose-additional-tops": "",
  "choose-lens-type": "",
  "choose-our-top-frames": "",
  "choose-subscription": "",
  "choose-top-frames": "",
  "choose-your-subscription-top": "",
  "choose-your-subscription-top-v2": "",
  "choose-your-top": "",
  "choose-your-tops": "",
  "choose-your-lens-type": "",
  "choose-your-lens-type-step": "",
  "create-your-password": "",
  "create-account": "",
  "create-pair-account": "",
  "cylinder": "",
  "cylinder-cyl": "",
  "cylinder-od": "",
  "cylinder-os": "",
  "crystals": "",
  "current-selected": "",
  "customize-your-lenses": "",
  "customize-your-lenses-step": "",
  "customer-satisfaction-96": "",
  "customer-satisfaction-93": "",
  "customer-satisfaction-95": "",
  // =============== D ===============
  "details": "",
  "doctor-name-required": "",
  "doctor-name": "",
  "doctor-phone-required": "",
  "doctor-phone": "",
  "doctor-state": "",
  "account-prompt": "",
  "download-invoice": "",
  "decline-invitation": "",
  "design": "",
  "design-your-product": ({ productName }: { productName: string }) => `${productName}`,
  "discount-code-applied": ({ discountCodeName }: { discountCodeName: string }) => `${discountCodeName}`,
  // =============== E ===============
  "earn-more-points": "",
  "earn-points-shopping": "",
  "easily-track-order": "",
  "edit-bundle": "",
  "edit-lense-selection": "",
  "edit-pair": "",
  "edit-color": "",
  "edit": "",
  "enhance-your-pair": "",
  "eye-doctor-contact": "",
  "empty-cart": "",
  "email": "",
  "email-address": "",
  "enter-order-info": "",
  "email-required": "",
  "email-subscription-confirmation": "",
  "enter-order-number": "",
  "enter-only-numeric": "",
  "enter-prescription-details": "",
  "enter-prescription-receive-frame": "",
  "enter-valid-order-number": "",
  "enter-your-prescription": "",
  "entered-manually": "",
  "enter-eye-doc-info": "",
  "enter-eye-doc-info-2": "",
  "exit": "",
  "extra-15-off": "",
  "extra-narrow": "",
  "extra-wide": "",
  "explore-all-tops": "",
  "explore-collection": "",
  "explore-favorites": "",
  "explore-product": ({ productName }: { productName: string }) => `${productName}`,
  "explore-eyewear": "",
  "explore-sunglasses": "",
  "explore-the-base": ({ baseName }: { baseName: string }) => `${baseName}`,
  "explore-top-frames": "",
  "expedite-order-processing": "",
  "eyeglasses": "",
  // ============================== Errors =============================================
  "error-email-subscription": "",
  "error-notification": "",
  "error-occurred-text": "",
  "error-loading-order-details-message": "",
  "error-subscription-submission-upload": "",
  "error-subscription-submission-file-type": "",
  "error-uploading-pd-message": "",
  // =============== F ===============
  "face-shape-the-serra": "",
  "face-shape-the-twain": "",
  "face-shape-the-addison": "",
	"face-shape-the-brook": "",
  "face-shape-the-casper": "",
  "face-shape-the-cedro": "",
  "face-shape-the-drew": "",
  "face-shape-the-ella": "",
  "face-shape-the-finley": "",
  "face-shape-the-harper": "",
  "face-shape-the-jessie": "",
  "face-shape-the-kirby": "",
  "face-shape-the-larkin": "",
  "face-shape-the-otero": "",
  "face-shape-the-payton": "",
  "face-shape-the-murphy": "",
  "face-shape-the-otis": "",
  "face-shape-the-quinn": "",
  "face-shape-the-reese": "",
  "face-shape-the-soto": "",
  "face-shape-the-wanda": "",
  "face-shape-the-zuri": "",
  "favorites": "",
  "favorites-capitalized": "",
  "featured-frame-price": ({ frameTitle, frameSelected, framePrice }: { frameTitle: string, frameSelected: string, framePrice: string, }) => `${frameTitle} ${frameSelected} ${framePrice}`,
  "featured-frame-and-lenses-pricing": ({ frameTitle, frameSelected, framePrice }: { frameTitle: string, frameSelected: string, framePrice: string, }) => `${frameTitle} ${frameSelected} ${framePrice}`,
  "fetching-invoice": "",
  "fit-guide": "",
  "fit-and-material": "",
  "fill-out-this-field": "",
  "first-name": "",
  "frame-color": "",
  "frame-color-mix-crystal-silver": "",
  "frame-color-mix-black-gold": "",
  "frame-color-mix-green-gold": "",
  "frame-color-mix-pink-gold": "",
  "frame-color-mix-tortoise-gold": "",
  "frame-color-cystal-clear": "",
  "frame-crafting-instruction": ({ selectedFrame }: { selectedFrame: string }) => `${selectedFrame}`,
  "frame-fit-description": (
    { frameName, lensWidth, frameBridge, frameTemple, lensHeight, frameWidth, units }: { frameName: string, lensWidth: string, frameBridge: string, frameTemple: string, lensHeight: string, frameWidth: string, units: string }
  ) => `${frameName} ${lensWidth}-${frameBridge}-${frameTemple} ${lensHeight}${units} ${frameWidth}${units}.`,
  "frame-mix-material-description": "",
  "frame-sinlge-material-description": "",
  "frame-name-sizing-heading": ({ frameName }: { frameName: string }) => `${frameName}`,
  "frame-shape": "",
  "frame-size": "",
  "frame-sizing": "",
  "frame-width": ({ frameWidth }: { frameWidth: string }) => `${frameWidth}`,
  "frame-width-description": "",
  "frame-width-paragraph": "",
  "frame-width-title": "",
  "frames-adults": "",
  "frames-kids": "",
  "free-shipping": "",
  "free-shipping-and-return": "",
  "free-shipping-countdown": ({ currency, remaining }: { currency: string, remaining: string }) => `${currency}${remaining}`,
  "free-shipping-on-plus-orders": ({ currency, threshold }: { currency: string, threshold: string }) => `${currency}${threshold}`,
  "free-shipping-on-code-orders": ({ countryCode }: { countryCode: string }) => `${countryCode}`,
  "free-shipping-reward": ({ highlightedText }: { highlightedText: string }) => `${highlightedText}`,
  "free-shipping-highlighted-text":"",
  "free-shipping-on-over": ({ formattedThreshold }: { formattedThreshold: string }) => `${formattedThreshold}`,
  "free-shipping-over": ({ threshold }: { threshold: string }) => `${threshold}`,
  "free-returns-exchange": "",
  "free-returns-exchange-limitation": "",
  "free-trial-30-days": "",
  "free-us-shipping": "",
  "friends-referral-details": "",
  "for-the-variant": ({ variantOption }: { variantOption: string }) => `${variantOption}`,
  "forgot-your-password": "",
  "fsa-hsa-eligible": "",
  "fsa-hsa-eligible-case": "",
  "fsa-hsa-eligible-sentence-case": "",
  // =============== G ===============
  "get-in-touch": "",
  "get-started": "",
  "get-started-selecting": "",
  "gift-card": "",
  "gift-card-25": "",
  "gift-card-50": "",
  "gift-card-100": "",
  "gift-card-200": "",
  "gift-card-choose-design": "",
  "gift-card-choose-amount": "",
  "gift-card-tip": "",
  "gift-card-email-instruction": "",
  "gift-card-guidance": "",
  "gift-card-validation-all-included": "",
  "gift-card-validation-simple": "",
  "gift-card-validation-codes": ({ yourSingleWord }: { yourSingleWord: string }) => `${yourSingleWord}`,
  "gift-card-your-single-word": "",
  "give-get-25": "",
  "go-back": "",
  "got-it": "",
  "go-to-rewards": "",
  "go-to-your-cart": "",
  "got-to-step": ({ stepName }: { stepName: string }) => `${stepName}`,
  "grateful-phrase-PariCarse": "",
  "get-frames-faster": "",
  // =============== H ===============
  "have-us-contact-doctor": "",
  "help-find-order": "",
  "horizontal-prism": "",
  "horizontal-prism-od": "",
  "horizontal-prism-os": "",
  "how-redeem-points": "",
  "handy-lens-add-ons": "",
  "how-does-it-work": "",
  "home": "",
  "how-it-works": "",
  "how-use-store-credit": "",
  // =============== I ===============
  "i-have-two-pd-values": "",
  "ideal-for": "",
  "items-in-order": "",
  "items-counted": ({ itemCount }: { itemCount: string }) => `${itemCount}`,
  "item-counted": ({ itemCount }: { itemCount: string }) => `${itemCount}`,
  "items-added": ({ itemsNumber }: { itemsNumber: string }) => `${itemsNumber}`,
  "item-added": ({ itemsNumber }: { itemsNumber: string }) => `${itemsNumber}`,
  "include-at-symbol": ({ currentEmailInput }: { currentEmailInput: string }) => `${currentEmailInput}`,
  "included": "",
  "including-rx": "",
  "in-cart": "",
  "in-variant": ({ variant }: { variant: string }) => `${variant}`,
  "invalid-email-address": "",
  "invalid-file-type": "",
  "invalid-file-type-reupload": ({ fileTypes }: { fileTypes: string }) => `${fileTypes}`,
  "image-non-hovered": ({ imageTitle }: { imageTitle: string }) => `${imageTitle}`,
  "image-carousel": "",
  "image-hovered": ({ imageTitle }: { imageTitle: string }) => `${imageTitle}`,
  // =============== J ===============
  "join-pair": "",
  "join-pair-agreement": ({ termsOfServices, privacyPolicy }: { termsOfServices: string, privacyPolicy:string }) => `${termsOfServices} ${privacyPolicy}`,
  "just-browsing": "",
  "just-launched": "",
  // =============== K ===============
  "keep-shopping": "",
  // =============== L ===============
  "label-required": ({ label }: { label: string }) => `${label}`,
  "last-action-error": "",
  "last-slide-message": "",
  "last-3-months": "",
  "last-6-months": "",
  "last-name": "",
  "learn-how": "",
  "left-eye-os": "",
  "leave-a-little-note": "",
  "lens-type": "",
  "lens-color": "",
  "lens-height": ({ lensHeight }: { lensHeight: string }) => `${lensHeight}`,
  "lens-height-description": "",
  "lens-height-title": "",
  "lens-options": "",
  "lens-width": ({ lensWidth }: { lensWidth: string }) => `${lensWidth}`,
  "lens-width-title": "",
  "lens-width-description": "",
  "lenses": "",
  "lenses-packages-basic-copy": "",
  "lenses-packages-standard-copy": "",
  "lenses-packages-premium-lozenge": "",
  "lenses-packages-premium-copy": "",
  "learn-more": "Learn More",
  "lenses-transition-tint": "",
  "limited-edition": "",
  "link-pd-with-pair": "",
  "light-responsive": "",
  "log-in": "",
  "log-in-sentence-case": "",
  "log-in-for-favorites": "",
  "log-in-to-favorite": "",
  "log-out": "",
  "load-more": "",
  "loading": "",
  "loading-text": "",
  "loading-text-lowercase": "",
  "loading-collections": "",
  "looks-like-shopping-tops": "",
  // =============== M ===============
  "make-a-selection": "",
  "manage-pair-membership": "",
  "manage-your-order": "",
  "manually-prescription": "",
  "material": "",
  "measure": "",
  "measure-pd-now": "",
  "measure-your-pd": "",
  "measure-my-pd": "",
  "measuring-tool-issue": "",
  "measurement-tool-message": "",
  "method": "",
  "members-discount": "",
  "metal-temple": "",
  "medium": "",
  "monthly-subscription-info-description": "",
  "my-bundle": "",
  "my-subscription": "",
  "my-tab-name": ({ tabName }: { tabName: string }) => `${tabName}`,
  "my-collection-name": ({ collectionName }: { collectionName: string }) => `${collectionName}`,
  "my-favorites": "",
  "my-collection": "",
  "my-cart-with-colon": "",
  "my-lenses": "",
  "my-membership": "",
  "my-orders": "",
  "my-prescription": "",
  "my-rewards": "",
  "my-subscriptions": "",
  "my-empty-collection-title": "",
  "my-empty-collection-paragraph": "",
  "my-top-frames": "",
  "my-base-frame": "",
  "monthly-store-credit": "",
  "member-only-tops": "",
  "membership-options-disclaimer": "",
  "member-discount": "",
  "manage-membership": "",
  "mocked-review-1-description": "",
  "mocked-review-2-description": "",
  "mocked-review-3-description": "",
  "more-from-collection": "",
  "more-info-incoming": "",
 // =============== N ===============
  "num-tops": ({ numTops }: { numTops: string }) => `${numTops}`,
  "narrow": "",
  "narrowest": "",
  "new": "",
  "new-look-advertisement": "",
  "next-uppercase": "",
  "next-collection": "",
  "next-pair-discount-offer": ({ codeName }: { codeName: string }) => `${codeName}`,
  "no-available-filter": "",
  "no-order-found": "",
  "no-base-frames": "",
  "no-order-found-message": "",
  "no-orders-in-time-period": "",
  "no-orders-found": "",
  "no-tops-available-for-frame": "",
  "not-found": "",
  "not-looking-subscription": "",
  "no-favorites-heading": "",
  "no-favorites-body": "",
  "none": "",
  "non-rx": "",
  // =============== O ===============
  "offerings": "",
  "one-time": "",
  "one-time-offer-condition": "",
  "open-minicart": "",
  "open-type-dropdown": ({ type }: { type: string }) => `${type}`,
  "order-confirmation-email": "",
  "order-line-items": ({ orderItems }: { orderItems: string }) => `${orderItems}`,
  "order-number": "",
  "order-number-message": ({ orderNumber }: { orderNumber: string }) => `${orderNumber}`,
  "order-number-digit-restriction": "",
  "order-number-length-restriction": "",
  "order-placed": "",
  "order-tracker": "",
  "orders": "",
  "orders-placed": "",
  "ordered-date": ({ orderedDate }: { orderedDate: string }) => `${orderedDate}`,
  "ordered-previously": "",
  "original-payment-refund": "",
  "other-items": "",
  "or": "",
  "or-lowercase": "",
  "our-specialty": "",
  "our-lenses-description": "",
  // =============== P ===============
  "patient-name-required": "",
  "patient-full-name": "",
  "patient-birthdate-required": "",
  "patient-birthdate": "",
  "prescription-photo-uploaded": "",
  "pair-points-reward": ({ pointsBalance }: { pointsBalance: string }) => `${pointsBalance}`,
  "pair-points-until-reward": ({ pointsBalance, nextRewardPoints }: { pointsBalance: string, nextRewardPoints: string }) => `${pointsBalance}/${nextRewardPoints}`,
  "pair-care": "",
  "pair-care-word-pair": "",
  "pair-care-word-care": "",
  "pair-care-coverage-text": "",
  "pair-care-coverage-text-highlighted-word": "",
  "paircare-adjusted-price": ({ currencySimbol, oldPrice, newPrice, }: { currencySimbol: string, oldPrice: string, newPrice: string }) => `${currencySimbol}${oldPrice} ${currencySimbol}${newPrice}`,
  "pair-care-upsell-state-available-line-1": "",
  "pair-care-upsell-state-available-line-2": "",
  "pair-care-upsell-state-ineligible-line-1": "",
  "pair-care-upsell-state-ineligible-line-2": "",
  "pair-care-upsell-state-purchased-line-1": "",
  "pair-care-upsell-state-purchased-line-2": "",
  "pair-care-upsell-state-pending-line-1": "",
  "pair-care-upsell-state-pending-line-2": "",
  "pair-eyewear-24": "",
  "pause-play": "",
  "past-year": "",
  "password": "",
  "passwords-not-match": "",
  "please-take-other-peak": "",
  "pd-already-measured": "",
  "pd-already-measured-message": "",
  "pd-database-issue": ({ pd }: { pd: string }) => `${pd}`,
  "pd-not-needed": "",
  "pd-not-needed-message": "",
  "pd-question": "",
  "pd-received": "",
  "pd-received-successfully": ({ pd }: { pd: string }) => `${pd}`,
  "pd-value": "",
  "perfect-for-long-screen-time": "",
  "photo-uploaded": "",
  "place-order-remind-later": "",
  "plus-add": "",
  "polarized-lenses": "",
  "popular-lenses-recommendation": "",
  "premium": "",
  "premium-plus": "",
  "premium-ultra-thin-copy": "",
  "pre-set-saves": "",
  "previewing": "",
  "prev-collection": "",
  "prescription-lens-description": "",
  "prescription-lens-lineup": "",
  "prescription-lens-lowdown": "",
  "privacy-policy": "",
  "privacy-web-form": "",
  "promotion-code": ({ code }: { code: string }) => `${code}`,
  "product-type": "",
  "pending": "",
  "percentage-off": ({ percentageOff }: { percentageOff: string }) => `${percentageOff}`,
  "per-delivery": "",
  "personalized-shopping-experience": "",
  "progressive": "",
  "protect-your-pair": "",
  "protect-your-lenses": "",
  "protect-your-lens": "",
  "protect-base": "",
  "prescription-details-header": "",
  "prescription-details-paragraph": ({ productTitle, variantTitle }: { productTitle: string; variantTitle: string }) => `${productTitle} ${variantTitle}`,
  "pair-plus": "",
  "pupils-distance-message": "",
  "pupillary-distance": "",
  "pupillary-distance-od": "",
  "pupillary-distance-os": "",
  // =============== R ===============
  "rewards": "",
  "reader-strength": "",
  "refer-a-friend": "",
  "required": "",
  "remind-me-later": "",
  "removed": "",
  "remove": "",
  "remove-lens-selection": "",
  "required-inputs-missing": "",
  "remove-lens-and-subs": "",
  "readers-strength-error": "",
  "readers": "",
  "recent-order": "",
  "refer-most-recent-rx": "",
  "refresh": "",
  "reward-message-less-than-50": "",
  "reset-account-password": "",
  "reset-password": "",
  "reset-password-email": "",
  "review": "",
  "review-prescription-details": "",
  "reset-password-label": "",
  "reward-message-50-99": "",
  "reward-message-100-199": "",
  "reward-message-200-299": "",
  "reward-message-300-399": "",
  "reward-message-400-499": "",
  "reward-message-500-599": "",
  "reward-message-600-999": "",
  "reward-message-1000+": "",
  "right-eye-od": "",
  "rx-item-first": "",
  "rx-lens-lineup": "",
  "rx-type-is-progressive": ({ rxType, lensPack }: { rxType: string, lensPack: string }) => `${rxType} ${lensPack}`,
  "rx-type-text-sinlge-vision": "",
  "rx-type-text-progressives": "",
  "rx-type-text-readers": "",
  "rx-type-text-non-prescription": "",
  "rx-type-text-prescription": "",
  "rxss-field-option-cylinder-label": "",
  "rxss-field-option-prism-label": "",
  "rxss-field-option-pd": "",
  "rxss-field-option-pd-tooltip": "",
  "rxss-field-option-sphere-label": "",
  "rxss-fields-selection-copy": "",
  "rxss-fields-subtitle-copy": "",
  "rxss-fields-title-copy": "",
  "rxss-measure-pd-button-copy": "",
  "rxss-warning-message": "",
  // =============== S ===============
  "save-bundle-edits": "",
  "save-color": "",
  "save-favorite-items": "",
  "save-time-from-previous": ({ frame, frameColor }: { frame: string, frameColor: string}) => `${frame} ${frameColor}`,
  "subscriptions": "",
  "subscription": "",
  "score-access-to-offers": "",
  "sent-exclamation": "",
  "select-frame-shape": "",
  "select-one-or-more": "",
  "select-required-fields": "",
  "selected": "",
  "suggested-looks": "",
  "shop-all-base-frames": "",
  "shop-all-tops": "",
  "shop-collection": "",
  "shop-glasses": "",
  "shop-in-local": "",
  "show-less": "",
  "shop-kids": "",
  "show-more": "",
  "shop-men": "",
  "shop-now": "",
  "shop-product": ({ productName }: { productName: string }) => `${productName}`,
  "shop-tops": "",
  "shop-women": "",
  "shimmers": "",
  "status": "",
  "standard": "",
  "subtotal": "",
  "select-collection": "",
  "select-all-that-apply": "",
  "select": "",
  "select-any": "",
  "select-base-frame": "",
"select-measurement-first": "",
  "select-strength": "",
  "select-your-lenses": "",
  "select-your-lenses-step": "",
  "select-your-subscription-cadence": "",
  "sign-in": "",
  "sign-in-sentence-case": "",
  "sign-up": "",
  "similar-to-product": ({ productName }: { productName: string }) => `${productName}`,
  "single-vision": "",
  "sizing-for-product": ({ productName }: { productName: string }) => `${productName}`,
  "slide-image-enumarated": ({ index }: { index: string }) => `${index}`,
  "sneak-peak-carousel": ({ collectionName }: { collectionName: string }) => `${collectionName}`,
  "sold-out": "",
  "sphere": "",
  "sphere-sph": "",
  "sphere-od": "",
  "sphere-os": "",
  "start-referring": "",
  "starting-at": "",
  "starting-from-price": ({ amount }: { amount: string }) => `${amount}`,
  "submit": "",
  "submit-if-all-good": "",
  "submit-prescription": "",
  "subscription-info-description": "",
  "subscription-info-description-p2": "",
  "sun-black": "",
  "sun-brown": "",
  "sun-green": "",
  "sun-lens": "",
  "sun-lens-offerings": "",
  "sun-silver-reflective": "",
  "sun-pink-reflective": "",
  "sun-blue-reflective": "",
  "sun-green-reflective": "",
  "sunglasses": "",
  "successful-measure-pd": "",
  "swipe-to-view-more": "",
  // =============== T ===============
  "tag-icon": "",
  "take-on-the-go": "",
  "tax": "",
  "tax-included": "",
  "tap-reward-to-learn": "",
  "take-photo": "",
  "temple-length": ({ templeLength }: { templeLength: string }) => `${templeLength}`,
  "temple-length-description": "",
  "temple-length-title": "",
  "terms-of-service": "",
  "terms-of-services": "",
  "text-length-validation": ({ length }: { length: string }) => ``,
  "time-remaining": "",
  "tooltip-icon": "",
  "total": "",
  "total-discount": "",
  "tops-label-title": ({ topLabel }: { topLabel: string }) => `${topLabel}`,
  "top-frame-label": "",
  "top-frame-option": ({ frameName }: { frameName: string }) => `${frameName}`,
  "top-frames": "",
  "top-frames-attach-explanation": "",
  "top-frames-attach-question": "",
  "top-frames-fabrication": "",
  "top-frames-fabrication-question": "",
  "top-frames-question-answer": "",
  "top-frames-question": "",
  "top-frames-thickness": "",
  "top-frames-thickness-question": "",
  "top-frames-weight": "",
  "top-frames-weight-question": "",
  "tops-added": ({ topsAdded, topsLimit }: { topsAdded: string, topsLimit: string }) => `${topsAdded}/${topsLimit}`,
  "tops-bundle-limit": ({ topsLimit }: { topsLimit: string }) => `${topsLimit}`,
  "the-frame": ({ frame }: { frame: string }) => `${frame}`,
  "track-order-questions": "",
  "tracking-number": "",
  "trial-period": "",
  "trouble-saving-info": "",
  "true-lowercase": "",
  "tops-made-for-you": "",
  // =============== U ===============
  "upload-prescription": "",
  "upload-new": "",
  "upload-file": "",
  "uploaded-pdf-encrypted": "",
  "upload-photo": "",
  "uploaded-file-restrictions": ({ fileTypes, maxSize }: { fileTypes: string, maxSize: string }) => `${fileTypes} ${maxSize}`,
  "update-cart": "",
  "update-cart-bundle": "",
  "upgrade-your-lenses": "",
  "upgrade-to-premium-lenses": "",
  "use-men-default": "",
  "use-women-default": "",
  "user-comment-location": ({ user, location }: { user: string, location: string }) => `${user} ${location}`,
  // =============== V ===============
  "values-provided-uncommon": "",
  "variant-unavailable": "",
  "variant-unavailable-message": "",
  "varifocal": "",
  "vertical-prism": "",
  "vertical-prism-od": "",
  "vertical-prism-os": "",
  "video-thumbnail-5": ({ productType, productName }: { productType: string, productName: string }) => `${productType} ${productName}`,
  "video-thumbnail": ({ productType, productName, thumbNailIndex }: { productType: string, productName: string, thumbNailIndex:string }) => `${productType} ${productName} ${thumbNailIndex}`,
  "view-my-favorites": "",
  "view-less": "",
  "view-all-tops": "",
  "view-base-frame": "",
  "view-more": "",
  "view-order-details": "",
  "view-product": ({ productOption }: { productOption: string }) => `${productOption}`,
  "vip": "",
  "vip-club-membership": "",
  "vip-promo-description": "",
  "vip-benefit-1": "",
  "vip-benefit-2": "",
  "vip-benefit-3": "",
  "vip-benefit-4": "",
  "vip-benefit-5": "",
  "vip-benefit-6": "",
  "vip_price": "VIP PRICE",
  "vip-sign-up": "",
  "virtual-try-on": "",
  "view-micro-cart-quantaty": ({ cartQuantity }: { cartQuantity: string }) => `${cartQuantity}`,
  "view-cart": "",
  // =============== W ===============
  "wall-hanger-magic": "",
  "warranty-1-year": "",
  "welcome-back-text": ({ customerName }: { customerName: string }) => `${customerName}`,
  "welcome-back-title-case": ({ customerName }: { customerName: string }) =>  `${customerName}`,
  "welcome-customer": ({ customerName }: { customerName: string }) => `${customerName}`,
  "welcome-to-pair-eyewear": "",
  "whoops": "",
  "why-love-top-frames": "",
  "wide": "",
  "widest": "",
  "with-your-purchase": "",
  "wipe-cart-emoji": "",
  "warning-update-password-email": "",
  // =============== Y ===============
  "you-shop-in": ({ localeCopy }: { localeCopy: string }) => `${localeCopy}`,
  "your-email": "",
  "your-reward-level": "",
  "your-friends-mail": "",
  "you-pay": "",
  "you-would-like": "",
  "you-unlocked-pair-plus-perks": "",
  // =============== Z ===============
  "zoom": "",
};

export default es;
