const en = {
  // =============== A ===============
  "about-our-sun-tops": "About our Sun Tops",
  "about-product": ({ productName }: { productName: string }) => `About ${productName}:`,
  "about-to-remove": ({ prodcutRemoved }: { prodcutRemoved: string }) => `You are about to remove ${prodcutRemoved}.`,
  "accessories": "Accessories",
  "acetate": "Acetate",
  "account": "account",
  "account-being-activated": "Your account is being activated!",
  "account-being-created": "Your account is being created!",
  "account-card-list-items": ({ itemsNumber }: { itemsNumber: string }) => `+ ${itemsNumber} Items`,
  "account-card-list-item": ({ itemNumber }: { itemNumber: string }) => `+ ${itemNumber} Item`,
  "account-menu": "Account menu",
  "activate-account": "Activate Account",
  "add": "add",
  "add-od": "Add OD",
  "add-os": "Add OS",
  "add-to-cart": "Add to Cart",
  "add-to-cart-tops-redirected": "Add to Cart + Go to Tops",
  "add-to-cart-plus": "+ Add to Cart",
  "add-uppercase": "Add",
  "added": "Added",
  "added-to-cart": "Added to Cart",
  "added-to-wishlist": "Added to your wishlist!",
  "add-plus-continue": "Add to Cart + Continue",
  "add-a-top-frame": "Add a Top Frame",
  "adding": "Adding",
  "add-to-cart-go-to-tops": "Add to Cart & Go to Tops",
  "age-range-5-9": "5-9yrs",
  "age-range-8-12": "8-12yrs",
  "age-range-9-adult": "9-Adult",
  "all-about-subscriptions": "All About Subscriptions",
  "all-about-our-subscriptions": "All about our Subscriptions",
  "all-best-things-pairs": "All the best things come in Pairs.",
  "all-duties-and-taxes": "All duties and taxes are included, no hidden fees",
  "all-time": "all-time",
  "all-tops": "All Tops",
  "already-have-account-question": ({ signInText }: { signInText: string }) => `Already have an account? ${signInText}`,
  "alt-all-tops": ({ label }: { label: string }) => `${label}-tops`,
  "alt-collection-logo": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle} collection logo`,
  "alt-collection": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle} collection`,
  "alt-side-image": "side image",
  "alt-text-blue-swatch": "blue-swatch",
  "attention": "Attention",
  "attention-lowercase": "attention",
  "are-you-sure": "Are you sure?",
  "are-you-want-to-exit": "Are You Sure You Want to Exit?",
  "aria-label-previous-slide": "previous slide",
  "aria-label-previous-slide-capitalized": "Previous slide",
  "aria-label-next-slide": "next slide",
  "aria-label-next-slide-capitalized": "Next slide",
  "ask-a-stylist": "Ask a Stylist",
  "axis": "Axis",
  "axis-od": "Axis OD",
  "axis-os": "Axis OS",
  // =============== B ===============
  "back": "Back",
  "back-in-stock": "Back in Stock",
  "back-to-my-orders": "Back to My Orders",
  "background-pattern": "background pattern matching top frame style",
  "badge-best": "Best Seller",
  "badge-top-rated": "Top Rated",
  "base-frame": "Base Frame",
  "base-frame-below": "base frame below",
  "base-frame-color": "Base Frame Color",
  "base-frame-size": "Base Frame Size",
  "base-frame-shape": "Base Frame Shape:",
  "base-frame-sold-separately": "Base frame sold separately",
  "base-frames": "Base Frames",
  "base-frames-benefits": "Why You’ll Love Our Base Frames",
  "base-in-bi": "Base In (BI)",
  "base-out-bo": "Base Out (BO)",
  "base-up-bu": "Base Up (BU)",
  "base-down-bd": "Base Down (BD)",
  "basic": "Basic",
  "bifocal-lenses-modern-alternative": "A modern day alternative for bifocal lenses",
  "bl+lr": "BL + LR",
  "bl": "BL",
  "blue-light": "Blue Light",
  "blue-light-filtering": "Blue Light Filtering",
  "blue-light-offerings": "Blue Light Offerings",
  "browser-no-video-support": "Your browser does not support the video tag.",
  "bridge-width-size": ({ bridgeWidth }: { bridgeWidth: string }) => `Bridge Width: ${bridgeWidth}`,
  "bridge-width-title": "Bridge Width",
  "bridge-width-description": "The width of the bridge section",
  "buy-more": "Buy More, Save More",
  "build-and-purchase": "Build & Purchase",
  "build-bundle-and-save": "Build your own bundle of 3 to 4 Top Frames & save up to 15%",
  "build-from-previous-order": "Build From Previous Order",
  "build-purchase-edit-frame-save": "Please proceed through 'Build & Purchase' to make changes to the chosen Base Frame in your cart. Select 'Update Cart' to save your most recent edits!",
  "build-your-product": ({ productName }: { productName: string }) => `Build Your ${productName}`,
  "build-you-bundle-title": "Build Your Own Bundle",
  "build-you-bundle-subtitle": "Mix and match and save on your faves! Select either a 3 Top or a 4 Top Bundle. Choose your Base Frame shape, then select your Tops. Save 10% on 3 Top Bundles and 15% on 4 Top Bundles.",
  "build-you-bundle-size": "Select Bundle size",
  "build-you-bundle-base": "Select your Base Frame",
  "build-you-bundle-top": "Select Top Frames",
  "build-you-bundle-top-title": "Complete your Bundle with some Top Frames!",
  "bundle-and-save": "Bundle & Save",
  // =============== C ===============
  "call-doctor-question": "Have us call your doctor",
  "call-doctor-confirmation": "We'll call your doctor!",
  "cart-icon": "cartIcon",
  "cancel": "Cancel",
  "code-name-discount-pair": "NEXTPAIR",
  "color": "Color",
  "color-black": "Black",
  "color-blue-clear": "Blue Clear",
  "color-blue-reflective": "Blue Reflective",
  "color-blue-tortoise": "Blue Tortoise",
  "color-brown": "Brown",
  "color-green": "Green",
  "color-green-reflective": "Green Reflective",
  "color-tortoise": "Tortoise",
  "color-pink-clear": "Pink Clear",
  "color-pink-reflective": "Pink Reflective",
  "color-honey-clear": "Honey Clear",
  "color-gray-clear": "Gray Clear",
  "color-green-clear": "Green Clear",
  "color-lavender-clear": "Lavender Clear",
  "color-silver-reflective": "Silver Reflective",
  "collection": "collection",
  "collection-uppercase": "Collection",
  "collection-leaving": "Collection leaving soon!",
  "collection-logo": "collection logo",
  "collection-title": ({ collectionTitle }: { collectionTitle: string }) => `${collectionTitle} Collection`,
  "complex-rx-note": "These 1.67 high-index lenses are recommended for complex prescriptions but are not recommended for people under 18 as they are less impact resistant.",
  "complete-your-order-message": "Complete your order with our accessories",
  "complete-uncropped-picture": "A complete, uncropped picture of your valid prescription is perfect!",
  "compare": "Compare",
  "confirm-password": "Confirm Password",
  "continue": "Continue",
  "continue-entering-rx": "Continue entering Rx",
  "continue-to-cart": "Continue to Cart",
  "continue-shopping": "Continue shopping",
  "continue-shopping-title-case": "Continue Shopping",
  "classic": "Classic",
  "cleaning-kit": "The all-in-one kit includes lens cleaning spray, a micro-fiber cloth, and a carrying kit.",
  "clear": "Clear",
  "close": "close",
  "close-this-dialogue": "Close this dialogue",
  "close-microcart-popup": "Close microcart popup",
  "close-uppercase": "Close",
  "click-to-preview": "Click to Preview",
  "click-to-upload-file": "Click to upload or drag and drop your files here",
  "complete-your-pair": "Complete your Pair with some top frames!",
  "contact-customer-service": "contact our customer service team",
  "contact-customer-service-suggestion": "If you have any questions or concerns, don't hesitate to contact our customer service team at hello@paireyewear.com or call us at (646) 389-9692.",
  "contact-us": "Contact Us",
  "contact-us-button-title": "Opens ADA chatbot for immediate customer service",
  "coming-soon": "Coming Soon",
  "check-order-status": "To check the status of your order, please enter in your order number and email address",
  "check-readers-strength" : "Check your Readers Strength!",
  "checkout": "Checkout",
  "checkout-collections": "Check Out Our Collections",
  "check-pd-in-prescription": "In order to process your order right away, check your prescription to see if you have PD and if not, use our tool to measure it now!",
  "choose-additional-tops": "Choose Additional Tops",
  "choose-lens-type": "Choose Lens Type",
  "choose-our-top-frames": "Choose from hundreds of Top Frames to switch up your everyday style!",
  "choose-subscription": "Choose Subscription",
  "choose-top-frames": "Choose Top Frames",
  "choose-your-subscription-top": "1. Choose Your First Subscription Top",
  "choose-your-subscription-top-v2": "Choose Your First Subscription Top",
  "choose-your-top": "Choose Your Top(s)",
  "choose-your-tops": "Choose Your Tops",
  "choose-your-lens-type": "Choose Your Lens Type",
  "choose-your-lens-type-step": "2. Choose Your Lens Type",
  "create-your-password": "Create your password to activate your account.",
  "create-account": "Create Account",
  "create-pair-account": "Create a free Pair account",
  "cylinder": "Cylinder",
  "cylinder-cyl": "CYL",
  "cylinder-od": "Cylinder OD",
  "cylinder-os": "Cylinder OS",
  "crystals": "Crystals",
  "current-selected": "Current selected",
  "customize-your-lenses": "Customize Your Lenses",
  "customize-your-lenses-step": "2. Customize Your Lenses",
  "customer-satisfaction-96": "96% customer satisfaction with this additional lens",
  "customer-satisfaction-93": "93% Customer Satisfaction w/ This Lens Add-On",
  "customer-satisfaction-95": "95% Customer Satisfaction w/ These Lens Add-Ons",
  // =============== D ===============
  "details": "Details",
  "doctor-name-required": "Doctor or clinic name is required.",
  "doctor-name": "Doctor or Clinic Name",
  "doctor-phone-required": "Doctor or clinic phone is required.",
  "doctor-phone": "Doctor or Clinic Phone",
  "doctor-state": "Doctor or Clinic State/Province",
  "account-prompt": "Don’t have an account?",
  "download-invoice": "Download invoice",
  "decline-invitation": "Decline Invitation",
  "design": "Design",
  "design-your-product": ({ productName }: { productName: string }) => `Design Your ${productName}`,
  "discount-code-applied": ({ discountCodeName }: { discountCodeName: string }) => `Discount code ${discountCodeName} applied!`,
  // =============== E ===============
  "earn-more-points": "Earn More Points",
  "earn-points-shopping": "Earn points when you shop",
  "easily-track-order": "Easily track your order",
  "edit-bundle": "Edit Your Bundle",
  "edit-lense-selection": "To edit your lens selection, click on the edit button on the top of the cart.",
	"edit-pair": "Edit Your Pair",
  "edit-color": "Edit Color",
  "edit": "Edit",
  "enhance-your-pair": "Enhance your Pair with these lens extras!",
  "eye-doctor-contact": "Eye doctor will be contacted",
  "empty-cart": "Your cart is currently empty",
  "email": "Email",
  "email-address": "Email address",
  "email-required": "Email is required",
  "email-subscription-confirmation": "Thanks for subscribing! Please check your email to confirm your subscription.",
  "enter-order-info": "Enter your order info",
  "enter-order-number": "Please enter your order number",
  "enter-only-numeric": "Please only enter in numeric values",
  "enter-prescription-details": "Enter Prescription Details",
  "enter-prescription-receive-frame": "Entering your prescription is the fastest way to receive your new frames! You’ll lose the Rx information you’ve entered if you exit now.",
  "enter-valid-order-number": "Please enter a valid order number and email address.",
  "enter-your-prescription": "Enter Your Prescription Details",
  "entered-manually": "Entered in manually",
  "enter-eye-doc-info": "Enter in your eye doctor's information, and we'll reach out to obtain your prescription information",
  "enter-eye-doc-info-2": "Enter in your eye doctor's info, and we'll reach out to obtain your prescription.",
  "exit": "Exit",
  "extra-15-off": "EXTRA 15% OFF",
  "extra-narrow": "Extra-Narrow",
  "extra-wide": "Extra-Wide",
  "explore-all-tops": "Explore All Tops",
  "explore-collection": "Explore Collection",
  "explore-favorites": "Explore Your Favorites",
  "explore-product": ({ productName }: { productName: string }) => `Explore ${productName}`,
  "explore-eyewear": "Explore Eyewear",
  "explore-sunglasses": "Explore Sunglasses",
  "explore-the-base": ({ baseName }: { baseName: string }) => `Explore The ${baseName}`,
  "explore-top-frames": "Explore Top Frames",
  "expedite-order-processing": "Expedite your order processing!",
  "eyeglasses": "Eyeglasses",
  // ============================== Errors =============================================
  "error-email-subscription": "An error occurred. Please try again later.",
  "error-notification": "Error!",
  "error-occurred-text": "An error occurred",
  "error-loading-order-details-message": 'There was an error loading your order details or the order number does not exist. Please contact our Customer Service team if there was a mistake. Otherwise, click "Back to My Orders" to see your order history',
  "error-subscription-submission-upload": "There was an error with the submission upload. Please contact our Customer Service team.",
  "error-subscription-submission-file-type": "You have selected an invalid file type. Please upload a file with one of the following extensions:",
  "error-uploading-pd-message": "Please refresh this page and try again, or contact us and send your information directly to hello@paireyewear.com.",
  // =============== F ===============
  "face-shape-the-serra": "The Serra looks fantastic on most younger kids’ faces, and they particularly suit an oval, square, or oblong shape.",
  "face-shape-the-twain": "The Twain looks fantastic on most adult and older kids’ faces, and they particularly suit an oval or round shape.",
  "face-shape-the-addison": "The Addison looks fantastic on most adult faces, and they particularly suit an oval or round shape.",
	"face-shape-the-brook": "The Brook looks fantastic on most adult faces, and they particularly suit an oval, round, square, heart, diamond, or triangle shape.",
  "face-shape-the-casper": "The Casper looks fantastic on most adult faces, and they particularly suit an oval, round, heart, or diamond shape.",
  "face-shape-the-cedro": "The Cedro looks fantastic on most younger kids’ faces, and they particularly suit an oval or round shape.",
  "face-shape-the-drew": "The Drew looks fantastic on most adult faces, and they particularly suit an oval, round, or triangle shape.",
  "face-shape-the-ella": "The Ella looks fantastic on most adult faces, and they particularly suit an oval, round, square, heart, diamond, or triangle shape.",
  "face-shape-the-finley": "The Finley looks fantastic on most adult faces, and they particularly suit an oval or round shape.",
  "face-shape-the-harper": "The Harper looks fantastic on most adult faces, and they particularly suit an oval, round, heart, diamond, or triangle shape.",
  "face-shape-the-jessie": "The Jessie looks fantastic on most adult faces, and they particularly suit an oval, round, heart, diamond, or triangle shape.",
  "face-shape-the-kirby": "The Kirby looks fantastic on most adult faces, and they particularly suit an oval or round shape.",
  "face-shape-the-larkin": "The Larkin looks fantastic on most adult and older kids’ faces, and they particularly suit an oval or round shape.",
  "face-shape-the-otero": "The Otero looks fantastic on most older kids’ faces, and they particularly suit an oval or round shape.",
  "face-shape-the-payton": "The Payton looks fantastic on most adult faces, and they particularly suit an oval, heart, oblong, or triangle shape.",
  "face-shape-the-murphy": "The Murphy looks fantastic on most adult faces, and they particularly suit an oval, heart, oblong, or triangle shape.",
  "face-shape-the-otis": "The Otis looks fantastic on most adult faces, and they particularly suit an oval, square, or oblong shape.",
  "face-shape-the-quinn": "The Quinn looks fantastic on most adult faces, and they particularly suit an oval, round, heart, diamond, or triangle shape.",
  "face-shape-the-reese": "The Reese looks fantastic on most adult faces, and they particularly suit an oval, square, or diamond shape.",
  "face-shape-the-soto": "The Soto looks fantastic on most adult faces, and they particularly suit an oval, square, or oblong shape.",
  "face-shape-the-wanda": "The Wanda looks fantastic on most adult faces, and they particularly suit an oval, round, square, heart, diamond, or triangle shape.",
  "face-shape-the-zuri": "The Zuri looks fantastic on most adult faces, and they particularly suit an oval, round, square, heart, diamond, or triangle shape.",
  "favorites": "favorites",
  "favorites-capitalized": "Favorites",
  "featured-frame-price": ({ frameTitle, frameSelected, framePrice }: { frameTitle: string, frameSelected: string, framePrice: string, }) => `${frameTitle} ${frameSelected} ${framePrice}`,
  "featured-frame-and-lenses-pricing": ({ frameTitle, frameSelected, framePrice }: { frameTitle: string, frameSelected: string, framePrice: string, }) => `${frameTitle} ${frameSelected} Lenses ${framePrice}`,
  "fetching-invoice": "Fetching Invoice...",
  "fit-guide": "Fit Guide",
  "fit-and-material": "Fit & Material:",
  "fill-out-this-field": "Please fill out this field.",
  "first-name": "First Name",
  "frame-color": "Frame Color",
  "frame-color-mix-crystal-silver": "Mixed Material Crystal Clear Silver Metal",
  "frame-color-mix-green-gold": "Mixed Material Green Gold Metal",
  "frame-color-mix-pink-gold": "Mixed Material Pink Gold Metal",
  "frame-color-mix-tortoise-gold": "Mixed Material Tortoise Gold Metal",
  "frame-color-mix-black-gold": "Mixed Material Black Gold Metal",
  "frame-color-cystal-clear": "Crystal Clear",
  "frame-crafting-instruction": ({ selectedFrame }: { selectedFrame: string }) => `This Top Frame is made to instantly snap onto The ${selectedFrame} Base Frame.`,
  "frame-fit-description": (
    { frameName, lensWidth, frameBridge, frameTemple, lensHeight, frameWidth, units }: { frameName: string, lensWidth: string, frameBridge: string, frameTemple: string, lensHeight: string, frameWidth: string, units: string }
  ) => `. ${frameName} measures ${lensWidth}-${frameBridge}-${frameTemple} with a lens height of ${lensHeight}${units} and a head width of ${frameWidth}${units}.`,
  "frame-mix-material-description": "This frame is made of hand-polished, flexible, and durable acetate with metal temples",
  "frame-sinlge-material-description": "Our frames are made of hand-polished, flexible, and durable acetate",
  "frame-name-sizing-heading": ({ frameName }: { frameName: string }) => `${frameName} Sizing`,
  "frame-shape": "Frame Shape",
  "frame-size": "Frame Size",
  "frame-sizing": "Frame Sizing",
  "frame-width": ({ frameWidth }: { frameWidth: string }) => `Frame Width: ${frameWidth}`,
  "frame-width-description": "Frame width is the full horizontal width of the front of the frame.",
  "frame-width-paragraph": "The width of the frame",
  "frame-width-title": "Frame Width",
  "frames-adults": "Adult Frames",
  "frames-kids": "Kids Frames",
  "free-shipping": "Free Shipping",
  "free-shipping-and-return": "Free standard shipping and free returns",
  "free-shipping-countdown": ({ currency, remaining, highlightedText }: { currency: string, remaining: string, highlightedText: string }) => `You're ${currency}${remaining} away from ${highlightedText}!`,
  "free-shipping-on-plus-orders": ({ currency, threshold }: { currency: string, threshold: string }) => `Free Standard Shipping On Orders ${currency}${threshold}+`,
  "free-shipping-on-code-orders": ({ countryCode }: { countryCode: string }) => `Free Standard Shipping on All ${countryCode} Orders`,
  "free-shipping-reward": ({ highlightedText }: { highlightedText: string }) => `🎉 Congrats! You've unlocked ${highlightedText}!`,
  "free-shipping-highlighted-text":"free shipping",
  "free-shipping-on-over": ({ formattedThreshold }: { formattedThreshold: string }) => `Free standard shipping on orders over ${formattedThreshold}`,
  "free-shipping-over": ({ threshold }: { threshold: string }) => `Orders over ${threshold} ship free`,
  "free-returns-exchange": "Free returns or exchanges",
  "free-returns-exchange-limitation": "Works only with our Base Frames",
  "free-trial-30-days": "30 day risk free trial",
  "free-us-shipping": "Free Standard Shipping on All U.S. Orders",
  "friends-referral-details": "Give your friends $25 off their first order of $60 (after discounts) and get $25 (in points) for each successful referral!",
  "for-the-variant": ({ variantOption }: { variantOption: string }) => `for The ${variantOption}`,
  "forgot-your-password": "Forgot your Password",
  "fsa-hsa-eligible": "FSA and HSA Eligible or Insurance Reimbursement",
  "fsa-hsa-eligible-case": "Non-prescription sunglass lenses are not eligible for FSA or HSA.",
  "fsa-hsa-eligible-sentence-case": "FSA & HSA eligible or insurance reimbursement",
  // =============== G ===============
  "get-in-touch": "Get in touch",
  "get-started": "Get Started",
  "get-started-selecting": "Get started by selecting a ",
  "gift-card": "gift card",
  "gift-card-25": "$25 - A fun Top Frame of their choice",
  "gift-card-50": "$50 - Two totally new Tops",
  "gift-card-100": "$100 - Rx Base Frames, a Top, and a wall hanger",
  "gift-card-200": "$200 - Rx blue-light Base Frames, two Tops, + PAIRCare",
  "gift-card-choose-design": "1. Decide on the design",
  "gift-card-choose-amount": "2. Pick the Perfect Amount",
  "gift-card-tip": "Make the gift even greater with a fun message!",
  "gift-card-email-instruction": "We’ll shoot the gift card code to your email with instructions on how to redeem it at checkout.",
  "gift-card-guidance": "Need Some Gift Guidance?",
  "gift-card-validation-all-included": "Gift cards are valid on all Pair items, including Rx and non-prescription Base Frames, sunglasses, and Top Frames.",
  "gift-card-validation-simple": "Our gift cards have no additional processing fees–how cool is that?!",
  "gift-card-validation-codes": ({ yourSingleWord }: { yourSingleWord: string }) => `Although discount codes are not applicable towards ${yourSingleWord} gift card purchase, the recipient will be able to stack a discount code and their gift card code at checkout`,
  "gift-card-your-single-word": "your",
  "give-get-25": "Give $25, Get $25",
  "go-back": "Go Back",
  "got-it": "Got It",
  "go-to-rewards": "Go to My Rewards",
  "go-to-your-cart": "Go to Your Cart",
  "got-to-step": ({ stepName }: { stepName: string }) => `Go to ${stepName} Step`,
  "grateful-phrase-PariCarse": "Thanks for adding PairCare!",
  "get-frames-faster": "Get your frames faster!",
  // =============== H ===============
  "have-us-contact-doctor": "Have us contact your doctor",
  "help-find-order": "Help me find my order number",
  "horizontal-prism": "Horizontal Prism",
  "horizontal-prism-od": "H Prism OD",
  "horizontal-prism-os": "H Prism OS",
  "how-redeem-points": "How Do I Redeem My Points?",
  "how-it-works": "How It Works",
  "how-does-it-work": "How Does It Work?",
  "home": "Home",
  "handy-lens-add-ons": "The Handy Lens Add-Ons",
  "how-use-store-credit": "How Do I Use My Store Credit?",
  // =============== I ===============
  "i-have-two-pd-values": "I have 2 PD values",
  "ideal-for": "Ideal for:",
  "items-in-order": "Items in this order",
  "items-counted": ({ itemCount }: { itemCount: string }) => `${itemCount} items`,
  "item-counted": ({ itemCount }: { itemCount: string }) => `${itemCount} item`,
  "items-added": ({ itemsNumber }: { itemsNumber: string }) => `${itemsNumber} items added`,
  "item-added": ({ itemsNumber }: { itemsNumber: string }) => `${itemsNumber} item added`,
  "include-at-symbol": ({ currentEmailInput }: { currentEmailInput: string }) => `Please include an '@' in the email address. ${currentEmailInput} is missing an '@'.`,
  "included": "Included",
  "including-rx": "including Rx",
  "in-cart": "In Cart!",
  "in-variant": ({ variant }: { variant: string }) => `in ${variant}`,
  "invalid-email-address": "Invalid email address.",
  "invalid-file-type": "Invalid file type",
  "invalid-file-type-reupload": ({ fileTypes }: { fileTypes: string }) => `Invalid file type. Please upload a file with one of the following extensions: ${fileTypes}.`,
  "image-carousel": "Image carousel",
  "image-non-hovered": ({ imageTitle }: { imageTitle: string }) => `${imageTitle} - non-hovered`,
  "image-hovered": ({ imageTitle }: { imageTitle: string }) => `${imageTitle} - hovered`,
  // =============== J ===============
  "join-pair": "Join Pair",
  "join-pair-agreement": ({ termsOfServices, privacyPolicy }: { termsOfServices: string, privacyPolicy:string }) => `By clicking "Join Pair", you agree to our ${termsOfServices} and ${privacyPolicy}.`,
  "just-browsing": "Just Browsing",
  "just-launched": "Just Launched!",
  // =============== K ===============
  "keep-shopping": "Keep Shopping",
  // =============== L ===============
  "label-required": ({ label }: { label: string }) => `${label} required`,
  "last-action-error": "We're having trouble with your last action. Please try again later.",
  "last-slide-message": "This is the last slide; it loops back to the first slide",
  "last-3-months": "last 3 months",
  "last-6-months": "last 6 months",
  "last-name": "Last Name",
  "learn-how": "Learn how",
  "leave-a-little-note": "Leave a Little Note",
  "left-eye-os": "Left Eye (OS)",
  "lens-type": "Lens Type",
  "lens-color": "Lens Color",
  "lens-height": ({ lensHeight }: { lensHeight: string }) => `Lens Height: ${lensHeight}`,
  "lens-height-description": "The vertical diameter of one lens",
  "lens-height-title": "Lens Height",
  "lens-options": "Lens Options",
  "lens-width": ({ lensWidth }: { lensWidth: string }) => `Lens Width: ${lensWidth}`,
  "lens-width-title": "Lens Width",
  "lens-width-description": "The horizontal diameter of one lens",
  "lenses": "Lenses",
  "lenses-packages-basic-copy": "Lenses with optical clarity.",
  "lenses-packages-standard-copy": "Lenses with optical clarity and high-impact resistance.",
  "lenses-packages-premium-lozenge": "Popular Upgrade",
  "lenses-packages-premium-copy": "Lenses with optical clarity up to 35% thinner than basic. Best for high prescriptions (above +3.00 and below -6.00).",
  "learn-more": "Learn More",
  "lenses-transition-tint": "Lenses transition from clear to a darker tint when they are outside",
  "limited-edition": "Limited Edition",
  "link-pd-with-pair": "We just need your order number to link your PD with your Pair!",
  "light-responsive": "Light Responsive",
  "log-in": "Log In",
  "log-in-sentence-case": "Log in",
  "log-in-for-favorites": "Log in for Favorites",
  "log-in-to-favorite": "Log in to Favorite items",
  "log-out": "Log Out",
  "load-more": "Load More",
  "loading": "Loading...",
  "loading-text": "Loading",
  "loading-text-lowercase": "loading",
  "loading-collections": "Loading Collections...",
  "looks-like-shopping-tops": "Looks like you're shopping tops!",
  // =============== M ===============
  "make-a-selection": "Make a Selection",
  "manage-pair-membership": "Manage PAIR+ Membership",
  "manage-your-order": "View or Manage Your Order",
  "manually-prescription": "Manually Enter Prescription",
  "material": "Material",
  "measure": "Measure",
  "measure-pd-now": "Measure my PD Now",
  "measure-your-pd": "We’ve got your Rx—now we just need your pupillary distance (PD) to get started on your Pair. Use our handy	tool to measure your PD now!",
  "measure-my-pd": "Measure My PD",
  "measuring-tool-issue": "Hmmm... There was an issue loading our measuring tool. ",
  "measurement-tool-message": "Measurement tool can only be used once.",
  "method": "method",
  "members-discount": "Member's Discount",
  "metal-temple": "Metal",
  "medium": "Medium",
  "monthly-subscription-info-description": "Subscriptions are the perfect way to build your Pair collection while saving some coin. If you enroll, you’ll get one pre-selected Top Frame (for 10% off!) mailed to you every month. Feel free to swap, pause, adjust your delivery frequency, or cancel at anytime.",
  "my-bundle": "My Bundle",
  "my-subscription": "My Subscription",
  "my-tab-name": ({tabName}: { tabName: string }) => `My ${tabName}`,
  "my-collection-name": ({ collectionName }: { collectionName: string }) => `My ${collectionName}`,
  "my-favorites": "My Favorites",
  "my-collection": "My Collection",
  "my-cart-with-colon": "My Cart:",
  "my-lenses": "My Lenses",
  "my-membership": "My Membership",
  "my-orders": "My Orders",
  "my-prescription": "My Prescription",
  "my-rewards": "My Rewards",
  "my-subscriptions": "My Subscriptions",
  "my-empty-collection-title": "You haven't placed any orders yet!",
  "my-empty-collection-paragraph": "Once you do, your purchases will show up here.",
  "my-top-frames": "My Top Frames",
  "my-base-frame": "My Base Frame",
  "monthly-store-credit": "Monthly store credit",
  "member-only-tops": "Member-only tops",
  "membership-options-disclaimer": "With this purchase, you choose to become a Pair+ VIP Club member and understand that you will be charged $25/month, which is converted to store credit. You can pause or cancel at anytime. Your first trial month is free.",
  "member-discount": "Member Discount",
  "manage-membership": "Manage Membership",
  "mocked-review-1-description": "This is my first Pair purchase and now I know why they’re so popular! The quality is great and the ability to keep multiple looks in one eyeglass case is genius.",
  "mocked-review-2-description": "They have been really resilient against scratches. 1000/10 would recommend to anyone looking for affordable, yet durable glasses.",
  "mocked-review-3-description": "The prescription is accurate, the frames fit well and the toppers are so fun. Customer service was great and shipping was fast. Highly recommend trying them out!",
  "more-from-collection": "More From This Collection",
  "more-info-incoming": "More info incoming…",
  // =============== N ===============
  "num-tops": ({ numTops }: { numTops: string }) => `${numTops} Tops`,
  "narrow": "Narrow",
  "narrowest": "Narrowest",
  "new": "New!",
  "new-look-advertisement": "Your new look starts with clear sight.",
  "next-uppercase": "Next",
  "next-collection": "Next Collection",
  "next-pair-discount-offer": ({ codeName }: { codeName: string }) => `For your eyes only! Get $6 Off Your Next Base Frame With Code ${codeName}`,
  "no-available-filter": "No available filter options",
  "no-order-found": "No Order Found",
  "no-base-frames": "No Base Frames are available within these selected filters.",
  "no-order-found-message": "We could not find this order in our system.",
  "no-orders-in-time-period": "No orders within this time period!",
  "no-orders-found": "You have no orders!",
  "no-tops-available-for-frame": "No tops available for your selected frame shape",
  "not-found": "Not Found",
  "not-looking-subscription": "Not Looking for a Subscription",
  "no-favorites-heading": "You currently have no Favorites",
  "no-favorites-body": "Start exploring and save your favorite top frames now!",
  "none": "None",
  "non-rx": "Non-RX",
  // =============== O ===============
  "offerings": "Offerings",
  "one-time": "One-Time",
  "one-time-offer-condition": "This one-time offer can only be applied to 1 Base Frame & cannot be combined with other discounts.",
  "open-minicart": "Open Minicart",
  "open-type-dropdown": ({ type }: { type: string }) => `Open ${type} dropdown`,
  "order-confirmation-email": "Check your email for an order confirmation, or reach out to us via chat!",
  "order-line-items": ({ orderItems }: { orderItems: string }) => `(${orderItems} items)`,
  "order-number": "Order Number",
  "order-number-message": ({ orderNumber }: { orderNumber: string }) => `Order #${orderNumber}`,
  "order-number-digit-restriction": "The order number should have digits only",
  "order-number-length-restriction": "Your order number should be 5 characters or more.",
  "order-placed": "order placed",
  "order-tracker": "Order tracker",
  "orders": "orders",
  "orders-placed": "orders placed",
  "ordered-date": ({ orderedDate }: { orderedDate: string }) => `Ordered on ${orderedDate}`,
  "ordered-previously": "Ordered Previously",
  "original-payment-refund": "All refunds will be issued to the original payment method — no exceptions",
  "other-items": "Other Items",
  "or": "OR",
  "or-lowercase": "or",
  "our-specialty": "Our Specialty",
  "our-lenses-description": "Our lenses are polarized and filter 100% of UVA & UVB rays to keep your eyes protected. Goodbye glare and eye-strain—Hello Pair.",
  // =============== P ===============
  "patient-name-required": "Patient's full name is required.",
  "patient-full-name": "Patient's Full Name",
  "patient-birthdate-required": "Patient's birthdate is required.",
  "patient-birthdate": "Patient's Birthdate",
  "prescription-photo-uploaded": "Photo of prescription uploaded",
  "pair-points-reward": ({ pointsBalance }: { pointsBalance: string }) => `You have  ${pointsBalance} Pair Points`,
  "pair-points-until-reward": ({ pointsBalance, nextRewardPoints }: { pointsBalance: string, nextRewardPoints: string }) => `${pointsBalance}/${nextRewardPoints} Pair Points until your next reward`,
  "pair-care": "Pair Care",
  "pair-care-word-pair": "Pair",
  "pair-care-word-care": "Care",
  "pair-care-coverage-text": ({ faq }: { faq: string }) => `PairCare covers the full cost of the base frame and any lens add-ons. See our ${faq} for more details.`,
  "pair-care-coverage-text-highlighted-word": "FAQ",
  "paircare-adjusted-price": ({ currencySimbol, oldPrice, newPrice, }: { currencySimbol: string, oldPrice: string, newPrice: string }) => `The price of PairCare has been adjusted from ${currencySimbol}${oldPrice} to ${currencySimbol}${newPrice} based on the cost of your glasses.`,
  "pair-care-upsell-state-available-line-1": "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
  "pair-care-upsell-state-available-line-2": "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
  "pair-care-upsell-state-ineligible-line-1": "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
  "pair-care-upsell-state-ineligible-line-2": "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
  "pair-care-upsell-state-purchased-line-1": "By applying PairCare to your order, you're taking the right steps to protect your Base Frame purchase.",
  "pair-care-upsell-state-purchased-line-2": "PairCare is a one-year warranty that covers Base Frames, lenses, and lens add-ons in the event your Pair is scratched or damaged within one year from delivery. PairCare does not include Top Frames and doesn't cover theft or loss of the item. For more information visit our FAQs.",
  "pair-care-upsell-state-pending-line-1": "Check your inbox for an email from us with payment details!",
  "pair-care-upsell-state-pending-line-2": "If you're unable to find the invoice in your email or need additional assistance, please",
  "pair-eyewear-24": "2024 Pair Eyewear",
  "pause-play": "pause/play",
  "past-year": "past year",
  "password": "Password",
  "passwords-not-match": "Passwords do not match.",
  "please-take-other-peak": "Please Take Another Peek",
  "pd-already-measured": "PD Already Measured",
  "pd-already-measured-message": "This order has the PD measured.",
  "pd-database-issue": ({ pd }: { pd: string }) => `Your PD is: ${pd}, but there was an issue recording your PD to our database. `,
	"pd-not-needed": "PD not Needed",
  "pd-not-needed-message": "Looks like this order has PD measured or doesn't need PD.",
  "pd-question": "Do You Have Your PD?",
  "pd-received": "PD Received!",
  "pd-received-successfully": ({ pd }: { pd: string }) => `Your PD has been received successfully. Your PD is: ${pd}. You may now close this page.`,
  "pd-value": "PD Value",
  "perfect-for-long-screen-time": "Perfect for those who spend a lot of time in front of a screen",
  "photo-uploaded": "Photo Uploaded!",
  "place-order-remind-later": "No problem. Place your order now and get reminded later.",
  "plus-add": "+Add",
  "polarized-lenses": "Polarized, scratch-resistant lenses that block 100% of UV rays",
  "popular-lenses-recommendation": "Most Love Their Pair with These Lenses",
  "premium": "Premium",
  "premium-plus": "Premium Plus",
  "premium-ultra-thin-copy": "These 1.67 high-index lenses are recommended for complex prescriptions but are not recommended for people under 18 as they are less impact resistant.",
  "pre-set-saves": "Save up to 15% with pre-set packs!",
  "previewing": "Previewing",
  "prev-collection": "Prev. Collection",
  "prescription-lens-description": "Prescription lens description",
  "prescription-lens-lineup": "The Prescription Lens Lineup",
  "prescription-lens-lowdown": "Here’s our prescription lens lowdown!",
  "privacy-policy": "Privacy Policy",
  "privacy-web-form": "Privacy Web Form",
  "promotion-code": ({ code }: { code: string }) => `Promotion ${code}`,
  "product-type": "Product Type",
  "pending": "Pending",
  "percentage-off": ({ percentageOff }: { percentageOff: string }) => `${percentageOff}% off`,
  "per-delivery": "Per delivery",
  "personalized-shopping-experience": "To personalize your shopping experience, please indicate which base frame you own.",
  "progressive": "Progressive",
  "protect-your-pair": "Protect your Pair in our secure and durable, yet lightweight case.",
  "protect-your-lenses": "Protect Your Lenses",
  "protect-your-lens": "Protect your lens",
  "protect-base": "Protect Your Base Frame",
  "prescription-details-header":"Prescription Details",
  "prescription-details-paragraph":({ productTitle, variantTitle }: { productTitle: string; variantTitle: string }) => `for ${productTitle}  in ${variantTitle}`,
  "pair-plus": "PAIR+",
  "pupils-distance-message": "Distance between your two pupils and needed before we can make your Pair!",
  "pupillary-distance": "Pupillary Distance",
  "pupillary-distance-od": "PD OD",
  "pupillary-distance-os": "PD OS",
  // =============== R ===============
  "rewards": "rewards",
  "reader-strength": "Reader Strength",
  "refer-a-friend": "Refer a Friend",
  "required": "Required",
  "remind-me-later": "Remind me later",
  "removed": "Removed",
  "remove": "Remove",
  "remove-lens-selection": " Doing this will also remove your lens selections. Please confirm.",
  "remove-lens-and-subs": " Doing this will also remove your lens selections and your subscription. Please confirm.",
  "required-inputs-missing": "These required inputs are missing:",
  "readers-strength-error": "Select the strength of your Readers to continue.",
  "readers": "Readers",
  "recent-order": "Recent order",
  "refer-most-recent-rx": "Refer to your most recent Rx for this information.",
  "refresh": "Refresh",
  "reset-account-password": "Reset account password",
  "reset-password": "Reset your password",
  "reset-password-email": "We will send you an email to reset your password.",
  "reset-password-label": "Reset Password",
  "review": "Review",
  "review-prescription-details": "Review Your Prescription Details",
  'reward-message-less-than-50': 'You are so close. 50 points earns you a $5 discount',
	'reward-message-50-99': 'You have $5 to use towards your next purchase',
	'reward-message-100-199': 'You have $10 to use towards your next purchase',
	'reward-message-200-299': 'You have $20 to use towards your next purchase',
	'reward-message-300-399': 'You have $30 to use towards your next purchase',
	'reward-message-400-499': 'You have $40 to use towards your next purchase',
	'reward-message-500-599': 'You have $50 to use towards your next purchase',
	'reward-message-600-999': 'You have +$60 to use towards your next purchase',
	'reward-message-1000+': 'You have +$100 to use towards your next purchase',
  "right-eye-od": "Right Eye (OD)",
  "rx-item-first": "We'll need to know which items appear on your Rx before moving to the next step!",
  "rx-lens-lineup": "The Rx Lens Lineup",
  "rx-type-is-progressive": ({ rxType, lensPack }: { rxType: string, lensPack: string }) => `${rxType} in ${lensPack}`,
  "rx-type-text-sinlge-vision": "Single-Vision",
  "rx-type-text-progressives": "Progressive Lenses",
  "rx-type-text-readers": "Readers",
  "rx-type-text-non-prescription": "Non-Prescription",
  "rx-type-text-prescription": "Prescription",
  "rxss-field-option-cylinder-label": "Cylinder (CYL) & Axis",
  "rxss-field-option-prism-label": "Prism",
  "rxss-field-option-pd": "Pupillary Distance*",
  "rxss-field-option-pd-tooltip": "No worries if you don't have this. We will guide you through the process of measuring it as a next step.",
  "rxss-field-option-sphere-label": "Sphere (SPH)",
  "rxss-fields-selection-copy": "Check off the measurements you see on your Rx:",
  "rxss-fields-subtitle-copy": "Enter your details now for the fastest delivery.",
  "rxss-fields-title-copy": "Grab Your Prescription To Get Started",
  "rxss-measure-pd-button-copy": "Measure Your Pupillary Distance",
  "rxss-warning-message": "These values are pretty uncommon, please confirm its accuracy. No action needed if this is correct.",
  // =============== S ===============
  "save-bundle-edits": 'Make changes to your bundle size, Base Frame shape, and Tops selections. Select "Update Bundle" to save your most recent edits!',
  "save-color": "Save Color",
  "save-favorite-items": "You can save your favorite items when you log in to your Pair Eyewear account. Get started today!",
  "save-time-from-previous": ({ frame, frameColor }: { frame: string,  frameColor: string}) => `Save time by applying lens selections from a previous order to The ${frame} in ${frameColor}`,
  "subscriptions": "subscriptions",
  "subscription": "Subscription",
  "score-access-to-offers": "Score access to exclusive deals & discounts",
  "sent-exclamation" : "Sent!",
  "select-frame-shape": "Select Frame Shape",
  "select-one-or-more": "Select One or More",
  "select-required-fields": "Select Required Fields",
  "selected": "Selected",
  "suggested-looks": "Looks We Think You’ll Love",
  "shop-all-base-frames": "Shop All Base Frames",
  "shop-all-tops": "Shop All Tops",
  "shop-collection": "Shop the Collection",
  "shop-glasses": "Shop Glasses",
  "shop-in-local": "Shop in local currency",
  "show-less": "Show Less",
  "shop-kids": "Shop Kids",
  "show-more": "Show More",
  "shop-men": "Shop Men",
  "shop-now": "Shop Now",
  "shop-product": ({ productName }: { productName: string }) => `Shop ${productName}`,
  "shop-tops": "Shop Tops you’ve had your eye on!",
  "shop-women": "Shop Women",
  "shimmers": "Shimmers",
  "status": "Status",
  "standard": "Standard",
  "subtotal": "Subtotal",
  "select-collection": "Select Collection",
  "select-all-that-apply": "Select all that apply",
  "select": "Select",
  "select-any": "Select Any",
  "select-base-frame": "Select Base Frame",
  "select-measurement-first": "Select Your Measurement First",
  "select-strength": "Select Strength",
  "select-your-lenses": "Select Your Lenses",
  "select-your-lenses-step": "1. Select Your Lenses",
  "select-your-subscription-cadence": "2. Select Your Subscription Cadence",
  "sign-in": "Sign In",
  "sign-in-sentence-case": "Sign in",
  "sign-up": "Sign Up",
  "similar-to-product": ({ productName }: { productName: string }) => `Similar to ${productName}`,
  "single-vision": "Single Vision",
  "sizing-for-product": ({ productName }: { productName: string }) => `Sizing for ${productName}`,
  "slide-image-enumarated": ({ index }: { index: string }) => `slide image ${index}`,
  "sneak-peak-carousel": ({ collectionName }: { collectionName: string }) => `${collectionName} Sneak Peak Carousel`,
  "sold-out": "Sold out!",
  "sphere": "Sphere",
  "sphere-sph": "SPH",
  "sphere-od": "Sphere OD",
  "sphere-os": "Sphere OS",
  "start-referring": "Start Referring",
  "starting-at": "Starting at ",
  "starting-from-price": ({ amount }: { amount: string }) => `Starting from ${amount}`,
  "submit": "Submit",
  "submit-if-all-good": "If everything looks good, click submit!",
  "submit-prescription": "Submit Prescription",
  "subscription-info-description": "Subscriptions are the perfect way to build your Pair collection while saving some coin. If you enroll, you’ll get one pre-selected Top Frame (for 10% off!) mailed to you every one, two, or three months. Feel free to swap, pause, or cancel at anytime",
  "subscription-info-description-p2": "Before each subscription order ships, you'll receive an email where you can preview the next Top in your subscription. If the Top Frame that’s next up in your subscription isn’t quite what you’re looking for, you can swap for another one of our pre-selected best-sellers.",
  "sun-black": "Sun - Black",
  "sun-brown": "Sun - Brown",
  "sun-green": "Sun - Green",
  "sun-lens": "Sun Lens",
  "sun-lens-offerings": "Sun Lens Offerings",
  "sun-silver-reflective": "Sun - Silver Reflective",
  "sun-pink-reflective": "Sun - Pink Reflective",
  "sun-blue-reflective": "Sun - Blue Reflective",
  "sun-green-reflective": "Sun - Green Reflective",
  "sunglasses": "Sunglasses",
  "successful-measure-pd": "PD measured successfully!",
  "swipe-to-view-more": "Swipe to view more",
  // =============== T ===============
  "tag-icon": "tagIcon",
  "take-on-the-go": "Take two more of your favorite top frames on-the-go with our ultra-portable case.",
  "tax": "Tax",
  "tax-included": "Tax Included",
  "tap-reward-to-learn": "Tap on the reward to learn more",
  "take-photo": "Take Photo",
  "temple-length": ({ templeLength }: { templeLength: string }) => `Temple Length: ${templeLength}`,
  "temple-length-description": "The length of the temple arm, from the front of the frame to the end tip",
  "temple-length-title": "Temple Length",
  "terms-of-service": "Terms of Service",
  "terms-of-services": "Terms of Services",
  "text-length-validation": ({ length }: { length: string }) => `Please lengthen this text to 5 characters or more (you are currently using ${length} characters).`,
  "time-remaining": "Time remaining until drop",
  "tooltip-icon": "tooltip icon",
  "total": "Total",
  "total-discount": "Total Discount",
  "tops-label-title": ({ topLabel }: { topLabel: string }) => `${topLabel} Tops`,
  "top-frame-label": "Top Frame",
  "top-frame-option": ({ frameName }: { frameName: string }) => `Top Frame ${frameName}`,
  "top-frames": "Top Frames",
  "top-frames-attach-explanation": "The magic is in the magnets. Two invisible, ultra-lightweight magnets (we’re talking < 1 gram), cover the top left and right corners of the frames, firmly attaching the top frame.",
  "top-frames-attach-question": "How Do Top Frames Attach to Base Frames?",
  "top-frames-fabrication": "Durable, injected-molded plastic.",
  "top-frames-fabrication-question": "What Are Top Frames Made Out Of?",
  "top-frames-question-answer": "Add personality to your Pair with hundreds of swappable Top Frames that instantly snap onto your Base Frame. We have tops in unique patterns, bold colors, Sun Tops, and classics!",
  "top-frames-question": "What Are Top Frames?",
  "top-frames-thickness": "2mm. Our goal is to have our Pair Family seeing 20-20, so our Tops will not obstruct your vision.",
  "top-frames-thickness-question": "How Thick Are Top Frames?",
  "top-frames-weight": "The weight of a single Top Frame ranges from 2.6 to 3.5 grams, depending on the model. That’s lighter than a teaspoon of sugar! Our Sun Tops will typically add an additional 2-3 grams due to their lenses.",
  "top-frames-weight-question": "How Heavy Is a Top Frame?",
  "tops-added": ({ topsAdded, topsLimit }: { topsAdded: string, topsLimit: string }) => `${topsAdded}/${topsLimit} Tops added`,
  "tops-bundle-limit": ({ topsLimit }: { topsLimit: string, }) => `${topsLimit} Tops Bundle`,
  "the-frame": ({ frame }: { frame: string }) => `The ${frame}`,
  "track-order-questions": "Want to keep track of all your orders and earn rewards?",
  "tracking-number": "Tracking Number",
  "trial-period": "30-Day Trial With Free Returns",
  "trouble-saving-info": "We're having trouble saving your information. Please continue to checkout, and we'll follow up via email after your order has been placed!",
  "true-lowercase": "true",
  "tops-made-for-you": "These Tops were made for you!",
  // =============== U ===============
  "upload-prescription": "Upload Prescription",
  "upload-new": "Upload New",
  "upload-file": "upload",
  "uploaded-file-restrictions": ({ fileTypes, maxSize }: { fileTypes: string, maxSize: string }) => `${fileTypes} file (max size. ${maxSize})`,
  "uploaded-pdf-encrypted": "The uploaded PDF file appears to be password protected. Please upload a PDF without a password.",
  "upload-photo": "Upload a photo",
  "update-cart": "Update Cart",
  "update-cart-bundle": "Update Bundle",
  "upgrade-your-lenses": "Upgrade your Lenses",
  "upgrade-to-premium-lenses": "Upgrade your lenses with premium ultra thin, progressive, and these below",
  "use-men-default": "Men's Average",
  "use-women-default": "Women's Average",
  "user-comment-location": ({ user, location }: { user: string, location: string }) => `${user} from ${location}`,
  // =============== V ===============
  "values-provided-uncommon": "values you have provided are pretty uncommon. Please confirm their accuracy and submit again.",
  "variant-unavailable": "Variant Unavailable",
  "variant-unavailable-message": "The variant is currently unavailable for your base frame.",
  "varifocal": "Varifocal",
  "vertical-prism": "Vertical Prism",
  "vertical-prism-od": "V Prism OD",
  "vertical-prism-os": "V Prism OS",
  "video-thumbnail-5": ({ productType, productName }: { productType: string, productName: string }) => `${productType} ${productName} video thumbnail 5`,
  "video-thumbnail": ({ productType, productName, thumbNailIndex }: { productType: string, productName: string, thumbNailIndex:string }) => `${productType} ${productName} video thumbnail ${thumbNailIndex}`,
  "view-less": "View Less",
  "view-more": "View More",
  "view-all-tops": "View All Tops",
  "view-base-frame": "View Base Frame",
  "view-my-favorites": "View My Favorites",
  "view-order-details": "View Order Details",
  "view-product": ({ productOption }: { productOption: string }) => `View ${productOption}`,
  "vip": "VIP",
  "vip-club-membership": "VIP CLUB MEMBERSHIP",
  "vip-promo-description": "Sign up for $25/month which is converted to store credit and unlock exclusive VIP perks as a Pair+ member.",
  "vip-benefit-1": "$25 of monthly store credit",
  "vip-benefit-2": "Extra 15% off sitewide",
  "vip-benefit-3": "Member-only sales",
  "vip-benefit-4": "Extra 15% Off All Items",
  "vip-benefit-5": "Points Based On Rewards Level",
  "vip-benefit-6": "No Minimum Purchase To Spend Points",
  "vip_price": "VIP PRICE",
  "vip-sign-up": "Sign up at cart, select the 'VIP' option. You can pause or cancel anytime.",
  "virtual-try-on": "Virtual Try-On",
  "view-micro-cart-quantaty": ({ cartQuantity }: { cartQuantity: string }) => `View Cart (${cartQuantity})`,
  "view-cart": "View Cart",
  // =============== W ===============
  "wall-hanger-magic": "Our wall hanger allows you to magically display up to 5 top frames and even has a pocket at the bottom for your base frame!",
  "warranty-1-year": "1-year warranty that offers full protection for scratched lenses or damaged frames.",
  "welcome-back-text": ({ customerName }: { customerName: string }) =>  `Welcome back, ${customerName}!`,
  "welcome-back-title-case": ({ customerName }: { customerName: string }) =>  `Welcome Back, ${customerName}!`,
  "welcome-customer": ({ customerName }: { customerName: string }) => `Hi ${customerName}, nice to see you!`,
  "welcome-to-pair-eyewear": "Welcome to Pair Eyewear",
  "whoops": "Whoops!",
  "why-love-top-frames": "Why You’ll Love Our Top Frames",
  "wide": "Wide",
  "widest": "Widest",
  "with-your-purchase": "With your purchase: ",
  "wipe-cart-emoji": "🧨 Wipe Cart 🧨",
  "warning-update-password-email": "We've sent you an email with a link to update your password.",
  // =============== Y ===============
  "you-shop-in": ({ localeCopy }: { localeCopy: string }) => `You’re shopping in ${localeCopy}`,
  "your-email": "Your Email",
  "your-reward-level": "Your Reward Level",
  "your-friends-mail": "Your Friends' Emails (Separated by Commas)",
  "you-pay":"You Pay:",
  "you-would-like": "We Think You'd Like",
  "you-unlocked-pair-plus-perks": "You unlocked PAIR+ Perks",
  // =============== Z ===============
  "zoom": "Zoom",
};

export default en;
